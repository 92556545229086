import { MetricsForm } from "components/analysis/metrics/metrics_config_form";
import dayjs from "dayjs";
import { TableSettings } from "specifics/table";

enum Key {
  UserToken = "UserToken",
  UserId = "UserId",
  MapActiveVehicleKeys = "MapActiveVehicleKeys",
  MetricsFormHistories = "MetricsFormHistories",
}

export class LocalStorageManager {
  public static getTableSettings = (tableKey: string): TableSettings => {
    const savedSettings = localStorage.getItem(tableKey);
    return savedSettings ? JSON.parse(savedSettings) : {};
  };

  public static setTableSettings = (
    tableKey: string,
    settings: TableSettings
  ) => {
    localStorage.setItem(tableKey, JSON.stringify(settings));
  };

  public static removeTableSettings = (tableKey: string) => {
    localStorage.removeItem(tableKey);
  };
} 