import React, { ReactNode, CSSProperties, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, Space, } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { ItemData } from "../deliveries_kanban";
import { DeliveryEditModal } from "../delivery_edit_modal";
import { OrderForm, } from "entities/order";
import { driverOption } from "./container";

interface ItemProps {
    item: ItemData;
    getItems: (id: string) => ItemData[];
    onSave: (order: OrderForm) => void;
    onDeleteDivided: (dividedId: string) => void;
    onDeleteOrder: (id: string) => void;
    isChartered?: boolean;
    driverOptions?: driverOption[],
    partnerVehicleOptions?: driverOption[]
}

export const Item: React.FC<ItemProps> = ({ item, isChartered, getItems, onSave, onDeleteDivided, onDeleteOrder, driverOptions, partnerVehicleOptions }) => {
    const style: CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
    };

    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }


    const loadingCompany = item.order?.orderDetails
        ?.filter(detail => detail.pointType === 2)
        .map(detail => ({
            ...detail.deliveryCompany,
            loadWeight: detail.loadWeight ?? 0,
            pointType: 2
        }));

    const unloadingCompany = item.order?.orderDetails
        ?.filter(detail => detail.pointType === 1)
        .map(detail => ({
            ...detail.deliveryCompany,
            loadWeight: detail.loadWeight ?? 0,
            pointType: 1
        }));

    // 積荷の詳細
    const loadingOrderDetails = item.orderDivided?.orderDetails?.filter(detail => detail.pointType === 2);
    const unloadingOrderDetails = item.orderDivided?.orderDetails?.filter(detail => detail.pointType === 1);

    const mismatch = (() => {
        const loadingTotal = loadingOrderDetails?.reduce((acc, detail) => {
            return {
                quantity: (acc.quantity || 0) + (detail.quantity || 0),
                loadWeight: (acc.loadWeight || 0) + Number(detail.loadWeight || 0)
            };
        }, { quantity: 0, loadWeight: 0 });

        const unloadingTotal = unloadingOrderDetails?.reduce((acc, detail) => {
            return {
                quantity: (acc.quantity || 0) + (detail.quantity || 0),
                loadWeight: (acc.loadWeight || 0) + Number(detail.loadWeight || 0)
            };
        }, { quantity: 0, loadWeight: 0 });

        const loadingExceeds = (loadingTotal?.quantity || 0) > (unloadingTotal?.quantity || 0) ||
            (loadingTotal?.loadWeight || 0) > (unloadingTotal?.loadWeight || 0);

        const unloadingExceeds = (unloadingTotal?.quantity || 0) > (loadingTotal?.quantity || 0) ||
            (unloadingTotal?.loadWeight || 0) > (loadingTotal?.loadWeight || 0);

        return loadingExceeds || unloadingExceeds ?
            {
                loadingExceeds,
                unloadingExceeds
            }
            :
            false
    })();

    return <div style={style}>
        {open &&
            <DeliveryEditModal
                open={open}
                order={item.order!}
                onCancel={closeModal}
                onSave={onSave}
                onDeleteOrder={onDeleteOrder}
                driverOptions={driverOptions ?? []}
                partnerVehicleOptions={partnerVehicleOptions ?? []}
                getItems={getItems}
                isChartered={isChartered}
            />}
        <Card
            style={{
                width: "100%",
                padding: "0 20px",
                border: mismatch ? "1px solid #FD8B8B" : "1px solid #D1D5DB",
                background: mismatch ? "#FFEFEE" : "white"
            }}
            bodyStyle={{ color: "#4B5563", fontSize: 10, padding: 0, margin: "5px 0" }}
            title={
                <Space direction="vertical" size={0}>
                    {/* 新着 */}
                    {item.isNew && <span style={{ color: "#5C6171", display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: '#5DC24E',
                            marginRight: 4
                        }}>

                        </span>
                        更新あり
                    </span>
                    }
                    <Space style={{ justifyContent: "space-between", alignItems: "center" }}>
                        {/* 受注番号 */}
                        <span style={{ whiteSpace: "break-spaces", wordBreak: "break-all" }}>
                            受注番号：{item?.order?.orderNo}
                        </span>
                        {/* 配車カード削除ボタン */}
                        {item.parent !== "undefined" &&
                            <Button onClick={() => { onDeleteDivided(item.id) }} style={{ position: 'absolute', border: 0, top: 0, right: 0, background: "transparent", cursor: 'pointer' }} icon={<CloseOutlined style={{ fontSize: 14, color: '#919eab' }} />} />
                        }
                    </Space>
                </Space>
            }
            onClick={showModal} headStyle={{ fontSize: 10, minHeight: 25, padding: 0 }}
        >
            <div style={{ fontSize: (loadingOrderDetails?.[0]?.productName?.length || 0) > 12 ? 10 : 11, whiteSpace: "normal" }}>
                {/* 品名： */}
                {loadingOrderDetails?.[0]?.productName}{!!loadingOrderDetails?.[0]?.productName && (loadingOrderDetails?.length || 0) > 1 && "（他）"}
            </div>
            <div style={{ fontSize: 11, whiteSpace: "normal" }}>
                {/* 数量： */}
                重量：<span style={{ fontSize: 12, fontWeight: "bold" }}>{Number(item.assignedWeight).toFixed(1)}</span>/{item?.order?.loadWeight}kg
            </div>
            <div style={{ fontSize: 11, whiteSpace: "normal" }}>
                {/* 数量： */}
                数量：<span style={{ fontSize: 11, fontWeight: "bold" }}>{item.assignedQuantity || 0}</span>/{item?.order?.orderDetails?.filter(detail => detail.pointType === 2).reduce((acc, detail) => acc + (Number(detail.quantity) || 0), 0)}
            </div>
            {/* 丸印と会社名を横に並べ、縦線で繋ぐレイアウト */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                {[...(loadingCompany ?? []), ...(unloadingCompany ?? [])].map((company, index, array) => (
                    <div key={company.id} style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            {/* 丸印 */}
                            <div style={{
                                width: 16,
                                height: 16,
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexShrink: 0,
                                backgroundColor: index < (loadingCompany ?? []).length ? '#CDD2DC' : '#A9A8FF',
                            }}>
                                <svg width="12" height="12" viewBox="0 0 16 16">
                                    <circle cx="8" cy="8" r="6" fill="#FFFFFF" />
                                </svg>
                            </div>

                            {/* 会社名 */}
                            <div style={{ marginLeft: 8, fontSize: (company?.name?.length || 0) > 12 ? 10 : 11, whiteSpace: "pre-wrap" }}>
                                {company?.name}
                                {mismatch && ((company.pointType === 2 && mismatch.loadingExceeds) || (company.pointType === 1 && mismatch.unloadingExceeds)) &&
                                    <span style={{
                                        color: "white",
                                        backgroundColor: "#BF2333",
                                        padding: 2,
                                        marginLeft: 5,
                                        borderRadius: 5,
                                    }}>
                                        超過
                                    </span>
                                }
                            </div>
                        </div>
                        {/* 縦線 */}
                        {index < array.length - 1 && (
                            <div style={{
                                height: 10,
                                borderLeft: '2px solid #CDD2DC',
                                marginLeft: 7,
                            }} />
                        )}
                    </div>
                ))}
            </div>
        </Card>
    </div>
}


interface SortableItemProps {
    children: ReactNode;
    handlePosition?: "right" | "top";
    id: string;
    driver?: boolean;
    driverUnassigned?: boolean;
    isCharteredItem?: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = ({ children, handlePosition = "right", id, driver, driverUnassigned, isCharteredItem }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        right: 0,
        width: "100%",
        backgroundColor: "#F9FAFB",
        position: driver && driverUnassigned ? "sticky" : "relative",
        zIndex: driver && driverUnassigned ? 1 : 0,
        boxShadow: driver && driverUnassigned ? "-5px 0px 10px -5px rgba(0, 0, 0, 0.2)" : "none",
    };

    let handleStyle = {
        position: "absolute",
        fontSize: 36,
        color: "black",
        cursor: "grab"
    };

    const rightHandleStyle = {
        right: 25,
        top: 0,
        bottom: 0,
        transform: "rotate(90deg)"
    };

    const topHandleStyle = {
        right: 40,
        top: 0
    };

    if (handlePosition === "right") {
        handleStyle = {
            ...handleStyle,
            ...rightHandleStyle
        };
    } else if (handlePosition === "top") {
        handleStyle = {
            ...handleStyle,
            ...topHandleStyle
        };
    }

    return (driver && driverUnassigned) || isCharteredItem ? (
        <div style={{
            ...style,
        }}>
            {children}
        </div>
    ) : (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}
