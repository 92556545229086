import { calculateTotalLoadWeight } from "entities/delivery";
import { useContext, useEffect, useMemo, useState } from "react";
import { GoogleMapComponent } from "utils/google_map_component";
import { Form } from "utils/hooks";
import _ from "lodash";
import { Flex, Skeleton, Space, theme } from "antd";
import { GlobalStateContext } from "contexts/global_state_context";
import { CustomTag } from "specifics/tag";
import { Vehicle } from "entities/vehicle";
import { DeliveryCompany } from "entities/delivery_company";
import { HistoryProps } from "routes/app";
import { CookieManager } from "utils/cookie_manager";
import { Rotation } from "entities/rotation";
import { Order, OrderDivided, OrderStatus } from "entities/order";
import { DeliveryTerm } from "entities/contract";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";

export const DeliveriesMaps = (
  props: {
    form: Form<Rotation[]>; // FormのDelivery[] (可変)
    orders: Order[];
    vehicles: Vehicle[];
    deliveryPartnerCompanies: DeliveryPartnerCompany[];
    selectedDate: Date;
  } & HistoryProps
) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();

  const [delivererIds, setDelivererIds] = useState<
    (string | undefined)[] | undefined
  >(undefined);

  useEffect(() => {
    if (!delivererIds) {
      setDelivererIds(CookieManager.getMapActiveVehicleKeys(props.selectedDate));
    } else {
      CookieManager.setMapActiveVehicleKeys(props.selectedDate, delivererIds);
    }
  }, [delivererIds]);

  const dividedsOwn = props.form.object
    .flatMap((rotation) => rotation.orderDivideds)
  // .filter(
  //   // 自社のOrderDivided一覧
  //   (d: OrderDivided) => d.charterType === 0
  // );

  const nVehiclesAndPartners = useMemo(() => {
    const list = _.uniq(
      props.form.object
        .flatMap((rotation) => rotation.orderDivideds)
        .flatMap((divided) => [divided.vehicleId, divided.partnerCompanyId])
        .filter(Boolean)
    );

    return list.length || 1;
  }, [JSON.stringify(props.form.object)]);

  const colors = _.uniqBy(
    props.form.object
      .flatMap((rotation) => [
        { id: rotation.vehicleId, type: 'vehicle' },
        { id: rotation.partnerCompanyId, type: 'partner' }
      ])
      .filter(item => item.id),
    'id'
  ).map((item, index) => ({
    ...item,
    color: `hsl(${index * (360 / nVehiclesAndPartners)}, 80%, 60%)`,
  }));

  const markerOptions: (google.maps.MarkerOptions & { data?: any })[] =
    useMemo(() => {
      return props.form.object
        .flatMap((rotation) => rotation.orderDivideds)
        // ?.filter((divided) => divided.charterType === 0)
        ?.concat(
          props.orders.filter(
            (order) =>
              order.status !== OrderStatus.Assigned &&
              delivererIds?.some((id) => !id)
          )
        ) // 未割当 : deliveryにはvehicleIdが紐づいておらず、delivererIdsにnull | undefinedが含まれているときは、「未割当」にチェックが入っている。
        .map((divided, index) => {
          return divided.orderDetails
            ?.filter(
              (detail) =>
                detail.deliveryCompany?.point?.latitude &&
                detail.deliveryCompany?.point?.longitude
            )
            .map((detail) => {
              // 色はHue（色相）を変化させる。
              // Saturation（彩度）とLightness（輝度）は固定。
              const color = divided.vehicleId
                ? colors.find((color) => color.id === divided.vehicleId && color.type === 'vehicle')?.color
                : divided.partnerCompanyId
                  ? colors.find((color) => color.id === divided.partnerCompanyId && color.type === 'partner')?.color
                  : "#9a9a9a";
              return {
                position: {
                  lat: detail.deliveryCompany?.point?.latitude,
                  lng: detail.deliveryCompany?.point?.longitude,
                },
                icon: {
                  fillColor: color, //塗り潰し色
                  fillOpacity: 1, //塗り潰し透過率
                  path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                  scale: 1.6,
                  strokeColor: color, //枠の色
                  anchor: { x: 12, y: 24 },
                },
                data: detail.deliveryCompany,
                visible: delivererIds?.includes(divided.vehicleId!) || delivererIds?.includes(divided.partnerCompanyId!),
              };
            }) as (google.maps.MarkerOptions & { data?: any })[];
        })
        .reduce((acc, val) => acc.concat(val), []);
    }, [JSON.stringify(props.form.object), JSON.stringify(delivererIds)]);

  const N = markerOptions.length ?? 1;
  const [infoWindows, setInfoWindows] = useState<InfoWindows>({});

  const centerOfGravity = useMemo(() => {
    const visibleOptions = markerOptions.filter((opton) => opton.visible);
    setInfoWindows((infoWindows) => {
      const newInfoWindows: InfoWindows = {};
      Object.keys(infoWindows).forEach((key) => {
        if (visibleOptions.every((option) => option.data.id !== key)) {
          infoWindows[key].close();
        } else {
          newInfoWindows[key] = infoWindows[key];
        }
      });
      return newInfoWindows;
    });
    if (visibleOptions.length > 0) {
      return markerOptions
        .map((marker) => ({
          lat: Number(marker.position?.lat),
          lng: Number(marker.position?.lng),
        }))
        .reduce(
          (acc, cur) => {
            return {
              lat: acc.lat + cur.lat / N,
              lng: acc.lng + cur.lng / N,
            };
          },
          { lat: 0, lng: 0 }
        );
    }
  }, [JSON.stringify(markerOptions)]);

  type InfoWindows = {
    [deliveryCompanyId: string]: google.maps.InfoWindow;
  };

  return (
    <Skeleton loading={globalState.loading || !delivererIds}>
      <Flex vertical style={{ width: "100%" }}>
        <Space wrap style={{ marginBottom: 15 }}>
          <CustomTag
            style={{
              padding: "0px 8px",
              background: token.colorWhite,
              boxShadow: "1px 2px 3px 0px #1d264533",
            }}
          >
            <Space style={{ height: 28, alignItems: "center" }}>
              <input
                onChange={(e) => {
                  if (delivererIds?.some((id) => !id)) {
                    setDelivererIds(delivererIds.filter((vehicleId) => vehicleId));
                  } else {
                    setDelivererIds([...(delivererIds ?? []), undefined]);
                  }
                }}
                checked={delivererIds?.some((id) => !id)}
                type="checkbox"
                style={{
                  verticalAlign: -3,
                  height: 16,
                  width: 16,
                  accentColor: "#bababa",
                }}
              />
              <div>未割当</div>
            </Space>
          </CustomTag>
          {colors.map((delivererColor) => {
            const vehicle = props.vehicles.find(
              (vehicle) => vehicle.id === delivererColor.id
            );
            const partnerCompany = props.deliveryPartnerCompanies.find(
              (partnerCompany) => partnerCompany.id === delivererColor.id
            );
            const deliveriesRelatedToThisDeliverer: OrderDivided[] = JSON.parse(
              JSON.stringify(dividedsOwn)
            )?.filter((divided: OrderDivided) => {
              if (vehicle) {
                // 未割当でない
                return String(divided.vehicleId) === String(vehicle?.id)
              } else if (partnerCompany) {
                // 未割当でない
                return String(divided.partnerCompanyId) === String(partnerCompany?.id)
              } else {
                // 未割当
                return !divided.vehicleId;
              }
            });

            const currentLoadWeight: number = calculateTotalLoadWeight(
              deliveriesRelatedToThisDeliverer,
              (detail) => detail.pointType === 2
            );

            const loadingTooMuch: boolean =
              currentLoadWeight > (vehicle?.maxWeight ?? 0);
            return (
              <CustomTag
                style={{
                  padding: "10px",
                  background: token.colorWhite,
                  boxShadow: "1px 2px 3px 0px #1d264533",
                  borderRadius: 16,
                }}
                key={delivererColor.id}
              >
                <Space style={{ width: "100%" }} direction="vertical">
                  <Space>
                    <input
                      onChange={(e) => {
                        if (delivererIds?.includes(vehicle?.id || partnerCompany?.id)) {
                          setDelivererIds(
                            delivererIds.filter(
                              (vehicleId) => vehicleId !== vehicle?.id && vehicleId !== partnerCompany?.id
                            )
                          );
                        } else {
                          setDelivererIds([...(delivererIds ?? []), vehicle?.id || partnerCompany?.id]);
                        }
                      }}
                      checked={delivererIds?.includes(vehicle?.id || partnerCompany?.id)}
                      type="checkbox"
                      style={{
                        verticalAlign: -3,
                        height: 16,
                        width: 16,
                        accentColor: delivererColor.color,
                      }}
                    />
                    <div style={{ fontWeight: 700, fontSize: 16 }}>
                      {vehicle?.vehicleCode || partnerCompany?.name}
                    </div>
                  </Space>
                  {vehicle ? (
                    <>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        ドライバー名: {vehicle.user?.name}
                      </div>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        車格: {vehicle.weight}t
                      </div>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        最大積載量: {vehicle.maxWeight ?? "--"}t
                      </div>
                      <div
                        style={{
                          fontWeight: loadingTooMuch ? 700 : 500,
                          fontSize: 16,
                          color: loadingTooMuch ? token.colorError : token.colorText,
                          width: 240,
                        }}
                      >
                        現在の総積載量:
                        {currentLoadWeight.toFixed(1)}kg (
                        {Math.round((currentLoadWeight / ((vehicle.maxWeight ?? 1) * 1000)) * 100)}%)
                      </div>
                    </>
                  ) : partnerCompany ? (
                    <>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        運送会社名: {partnerCompany.name}
                      </div>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        担当者: {partnerCompany.repName}
                      </div>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        担当者連絡先: {partnerCompany.tel}
                      </div>
                      <div style={{ fontWeight: 500, fontSize: 16, color: token.colorText, width: 240 }}>
                        現在の総積載量: {currentLoadWeight.toFixed(1)}kg
                      </div>
                    </>
                  ) : null}
                </Space>
              </CustomTag>
            );
          })}
        </Space>
        <GoogleMapComponent
          style={{ height: 500 }}
          onClickMarker={(e, marker, data: DeliveryCompany) => {
            const infowindow = new google.maps.InfoWindow({
              content: `<div>
              <div>名称: ${data.name}</div>
              <div>ふりがな: ${data.kana}</div>
              <div>郵便番号: 〒${data.zipcode}</div>
              <div>住所: ${data.address}</div>
              <br/>
              <div>受入可能時間: ${data.availableHours}</div>
              <div>必要作業: ${data.needTask}</div>
              ${data.deliveryTerm
                  ? `<div>荷渡し条件: ${DeliveryTerm[data.deliveryTerm]}</div>`
                  : ""
                }
            </div>`,
            });
            infowindow.open(marker?.getMap(), marker);
            setInfoWindows((val: InfoWindows) => {
              val[data.id!] = infowindow;
              return val;
            });
          }}
          onDbclickMarker={(e, data) => {
            props.history.push(`/operations/delivery-companies/${data.id}`);
          }}
          mapCenter={centerOfGravity}
          initialZoom={8}
          markerOptions={markerOptions}
        />
      </Flex>
    </Skeleton>
  );
};