import { Flex, Space, message, theme } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import {
  MdDragIndicator,
  MdArrowForwardIos,
  MdLockOutline,
} from "react-icons/md";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useContext, useMemo, useState } from "react";

import { CustomTag } from "specifics/tag";

import { useSwapOrderApi } from "api/order";

import { GlobalStateContext } from "contexts/global_state_context";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { ID } from "entities";
import { Dictionary, groupBy } from "lodash";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Rotation } from "entities/rotation";
import { Vehicle, VehicleType } from "entities/vehicle";
import { OrderDetail, OrderDivided, OrderPointType, OrderPointTypes } from "entities/order";
import { AssignedPartnerCompanyDataDisplayEnum, AssignedPartnerCompanyDataInputEnum, AssignedPartnerCompanyDataForm, DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { OrderDetailManualModals } from "components/orders/orders_view";
import { Manual } from "entities/manual";
import { CustomButton } from "specifics/button";
import { convertToCamelCase, copyToClipboard } from "utils/util";
import ReactDOM from "react-dom";
import { QRPrintComponent } from "components/shared/manuals_share_form";
import { useGenerateTemporaryTokenApi } from "api/temporary_token";
import { CustomInputField } from "specifics/input";
import { useCheckManualExistApi } from "api/manual";
import { useEditDeliveryPartnerCompanyDataApi } from "api/delivery_partner_company";

export const DeliveriesAssigned = (props: {
  selectedDateString: string;
  rotations: Rotation[];
  onRotation: () => void;
  onDraft: () => void;
  vehicles: Vehicle[];
  deliveryPartnerCompanies: DeliveryPartnerCompany[];
  partnerCompanyData?: AssignedPartnerCompanyDataForm;
}) => {
  const { token } = theme.useToken();

  const swapApi = useSwapOrderApi();
  const draftPartnerCompanyDataApi = useEditDeliveryPartnerCompanyDataApi();
  const generateTokenApi = useGenerateTemporaryTokenApi();
  const assignManualApi = useCheckManualExistApi();
  const globalState = useContext(GlobalStateContext);
  const [selectedDetailId, setSelectedDetailId] = useState<string | null>();
  const [selectedManuals, setSelectedManuals] = useState<{ [key: string]: Manual }>({});

  const groupedRotations: Dictionary<Rotation[]> = useMemo(() => {
    // deliveryDateに基づいてグループ化
    return groupBy(props.rotations, (rotation) => {
      return rotation.partnerCompanyId ? rotation.partnerCompanyId + rotation.vehicleId : rotation.vehicleId;
    });
  }, [props.rotations]);

  const filterDeduplicateAndSortDetails = (orderDivideds: OrderDivided[]) => {
    return orderDivideds.flatMap((divided) => {
      return (divided.orderDetails || []).filter((detail) => {
        const hasDuplicate = divided.orderDetails?.some(
          (otherDetail) =>
            otherDetail !== detail && otherDetail.pointType === detail.pointType
        );
        return !hasDuplicate || (hasDuplicate && !!detail.loadWeight);
      });
    }).reduce((acc: OrderDetail[], detail: OrderDetail) => {
      if (!acc.some(d => d.deliveryCompanyId === detail.deliveryCompanyId && d.pointType === detail.pointType)) {
        acc.push(detail);
      }
      return acc;
    }, [])
      .sort((a, b) => (a?.turnInRotation || 0) - (b?.turnInRotation || 0));
  };

  useEffectSkipFirst(() => {
    globalState.setLoading(swapApi.loading);
    if (swapApi.isSuccess()) {
      props.onRotation();
    }
  }, [swapApi.loading]);
  useEffectSkipFirst(() => {
    if (draftPartnerCompanyDataApi.isSuccess()) {
      message.success("運送会社の入力情報を保存しました。");
      props.onDraft();
    }
  }, [draftPartnerCompanyDataApi.loading]);

  const assignedWeight = (rotation: Rotation) => {
    let totalLoadWeight = 0;
    rotation.orderDivideds?.forEach((divided) => {
      divided.orderDetails?.forEach((detail) => {
        if (detail.pointType === 2 && detail.loadWeight) {
          totalLoadWeight += parseFloat(detail.loadWeight?.toString() || "");
        }
      });
    });
    return totalLoadWeight ? Number(totalLoadWeight.toFixed(1)) : 0;
  };

  const assignedManual = async (detailId: string, deliveryCompanyId: string, partnerCompanyId?: string) => {
    const manualId = await assignManualApi.execute(deliveryCompanyId, undefined, partnerCompanyId);
    if (manualId) {
      setSelectedManuals(prev => ({
        ...prev,
        [detailId]: manualId
      }));
    }
    return manualId;
  }

  const delivererUrls = async () => {
    // 一時トークンを1回だけ生成
    const tokenResponse = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.qrExpiration });
    const token = tokenResponse.data.token;

    const results = [];
    // 完全な逐次処理
    for (const [delivererId, rotations] of Object.entries(groupedRotations)) {
      const vehicle = props.vehicles.find(vehicle => vehicle.id === delivererId);
      const partnerCompany = props.deliveryPartnerCompanies.find(company => delivererId.includes(company.id || ''));
      const partnerVehicle = props.deliveryPartnerCompanies.flatMap(company => company.deliveryPartnerVehicles).find(vehicle => delivererId.includes(vehicle?.id || ''));
      const details = partnerCompanyDataForm.getValue([props.selectedDateString, partnerVehicle?.id || '']);

      const deliveryUrls = [];
      for (const rotation of rotations) {
        const orderDetails = filterDeduplicateAndSortDetails(rotation.orderDivideds);
        for (const detail of orderDetails) {
          const deliveryCompanyId = detail.deliveryCompanyId || '';
          if (!detail?.id) {
            continue;
          }
          // 1つずつ順番に処理
          const manual = selectedManuals[detail.id as string] || await assignedManual(detail.id, deliveryCompanyId, partnerCompany?.id);
          if (!manual?.id) {
            continue;
          }
          deliveryUrls.push({
            url: `${process.env.REACT_APP_ADMIN_HOST}manuals/${manual?.id}?token=${token}&delivererId=${delivererId}`,
            title: `${OrderPointTypes[detail?.pointType as OrderPointType]}:${detail?.deliveryCompany?.name}`,
            order: `${(rotation.rotationTurn || 0) + 1}回転目`
          });
        }
      }

      if (deliveryUrls.length > 0) {
        results.push({
          name: vehicle?.user?.name || partnerCompany?.name,
          urls: deliveryUrls,
          details,
        });
      }
    }
    return results;
  };

  const partnerCompanyDataForm = useForm<AssignedPartnerCompanyDataForm>(
    {
      ...props.deliveryPartnerCompanies.flatMap(partnerCompany => partnerCompany.deliveryPartnerVehicles)
        .filter(patnerVehicle => props.rotations.map(rotation => rotation.vehicleId).includes(patnerVehicle?.id))
        .reduce((acc, patnerVehicle) => {
          acc[props.selectedDateString] = {
            ...acc[props.selectedDateString],
            [patnerVehicle?.id as string]: {
              ...convertToCamelCase(props.partnerCompanyData)[props.selectedDateString]?.[patnerVehicle?.id as string],
              vehicleType: VehicleType[patnerVehicle?.vehicleType as VehicleType],
              weight: patnerVehicle?.weight + "t",
              maxWeight: patnerVehicle?.maxWeight + "t",
            }
          };
          return acc;
        }, {} as AssignedPartnerCompanyDataForm),
    });

  return (
    <div
      css={css`
  .ant - picker - input > input,
        .ant - picker - input {
  font - weight: 700;
  color: #111827;
  font - size: 18px;
  cursor: pointer;
}
        .ant - picker - suffix {
  color: #111827;
}
        .ant - picker - content th,
        .ant - picker - content td {
  font - weight: 600;
}
        .ant - picker - large {
  padding: 0;
}
        .ant - picker - cell - selected {
  background: #e7e7e7;
}
`}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
          height: "100vh",
          background: token.colorBgBase,
        }}
      >
        <Space size={16} direction="vertical" style={{ padding: 16 }}>
          <Flex gap={5}>
            <CustomButton
              size="small"
              type="primary"
              onClick={async () => {
                handleBulkURLCopy(await delivererUrls());
              }}
            >
              URLをコピー
            </CustomButton>
            <CustomButton
              size="small"
              type="primary"
              onClick={async () => {
                handleAdminBulkQRPrint(await delivererUrls());
              }}
            >
              QRコードを印刷
            </CustomButton>
            <CustomButton
              size="small"
              type="primary"
              onClick={() => {
                draftPartnerCompanyDataApi.execute(partnerCompanyDataForm);
              }}
            >
              運送会社の入力情報を保存
            </CustomButton>
          </Flex>
          {props.rotations.length === 0 ? (
            <Space
              size={32}
              direction="vertical"
              style={{ textAlign: "center", marginTop: 40 }}
            >
              <Space
                style={{
                  marginTop: 8,
                  textAlign: "center",
                  width: globalState.dimension.width - 32,
                }}
                size={24}
                direction="vertical"
              >
                <MdLockOutline
                  style={{ height: 64, width: 64, color: "#9CA3AF" }}
                />
                <Space direction="vertical" size={13}>
                  <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                    まだ配送が作成されていません
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#6B7280",
                      padding: "0px 8px 0px 8px",
                    }}
                  >
                    運行管理者がまだ配送を作成していません。
                  </div>
                </Space>
              </Space>
            </Space>
          ) : (
            <div
              style={{
                display: "flex",
                gap: 10,
                background: "white",
                height: "100%",
                padding: "20px 15px",
                borderRadius: "16px",
              }}
            >
              {Object.keys(groupedRotations)
                .sort()
                .map((groupId) => {
                  const vehicle = props.vehicles.find(
                    (vehicle) => vehicle.id === groupId
                  );
                  const partnerCompany = props.deliveryPartnerCompanies.find(
                    (partnerCompany) => groupId.includes(partnerCompany.id || '')
                  );
                  const patnerVehicle = props.deliveryPartnerCompanies.flatMap(company => company.deliveryPartnerVehicles).find(
                    (vehicle) => groupId.includes(vehicle?.id || '')
                  );
                  return (
                    <Space
                      key={groupId}
                      direction="vertical"
                      style={{
                        backgroundColor: "rgb(249, 250, 251)",
                        padding: 10,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          color: "#6B7280",
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        {vehicle && (
                          `${vehicle.user?.name}（車格:${vehicle.weight} t、最大積載量:${vehicle.maxWeight || "--"} t）`
                        )}
                        {partnerCompany && (
                          `${partnerCompany.name}`
                        )}
                        {patnerVehicle && (
                          `(${VehicleType[patnerVehicle?.vehicleType]}${patnerVehicle.weight} t、最大積載量:${patnerVehicle.maxWeight} t)`
                        )}
                      </span>
                      {patnerVehicle &&
                        <>
                          {Object.entries(AssignedPartnerCompanyDataDisplayEnum).map(([label, attr]) => (
                            <CustomInputField
                              key={label}
                              addonBefore={label}
                              form={partnerCompanyDataForm}
                              disabled
                              attr={[props.selectedDateString, patnerVehicle.id || '', attr]}
                            />
                          ))}
                          {Object.entries(AssignedPartnerCompanyDataInputEnum).map(([label, attr]) => (
                            <CustomInputField
                              key={label}
                              addonBefore={label}
                              form={partnerCompanyDataForm}
                              attr={[props.selectedDateString, patnerVehicle.id || '', attr]}
                            />
                          ))}
                        </>
                      }
                      {groupedRotations[groupId].map(
                        (rotation: Rotation, index: number) => {
                          return (
                            <Space
                              key={rotation.id}
                              direction="vertical"
                              style={{
                                width: "285px",
                                background: "#E5E7EB",
                                border: "1px solid #E5E7EB",
                                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "6px",
                                padding: 10,
                              }}
                            >
                              <span style={{ fontSize: 16, fontWeight: 500 }}>
                                {index + 1}回転目
                              </span>
                              <table
                                style={{
                                  color: "#6B7280",
                                  width: "100%",
                                }}
                              >
                                {rotation.vehicleId &&
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          textAlign: "left",
                                          paddingRight: 10,
                                        }}
                                      >
                                        最大積載量
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          width: 1,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        :
                                      </td>
                                      <td>{rotation.maxWeight}t</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          textAlign: "left",
                                          paddingRight: 10,
                                        }}
                                      >
                                        現在の総積載量
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          width: 1,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {assignedWeight(rotation)}kg（
                                        {rotation.maxWeight
                                          ? Math.round(
                                            (Number(assignedWeight(rotation)) /
                                              (rotation.maxWeight * 1000)) *
                                            100
                                          )
                                          : 0}
                                        %）
                                      </td>
                                    </tr>
                                  </tbody>
                                }
                                {rotation.partnerCompanyId &&
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          textAlign: "left",
                                          paddingRight: 10,
                                        }}
                                      >
                                        現在の総積載量
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          width: 1,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {assignedWeight(rotation)}kg
                                      </td>
                                    </tr>
                                  </tbody>
                                }
                              </table>
                              <DragDropContext
                                onDragEnd={(result: DropResult) => {
                                  const { source, destination } = result;
                                  if (
                                    !destination ||
                                    source.droppableId !==
                                    destination.droppableId ||
                                    source.index === destination.index ||
                                    !rotation.orderDivideds
                                  ) {
                                    return;
                                  }
                                  const details = filterDeduplicateAndSortDetails(rotation.orderDivideds);
                                  const currentIds = details.map(
                                    (detail) => detail.id || ""
                                  );
                                  const [removed] = currentIds.splice(
                                    source.index,
                                    1
                                  );
                                  currentIds.splice(
                                    destination.index,
                                    0,
                                    removed
                                  );
                                  swapApi.execute({
                                    ids: currentIds.map(
                                      (id: ID, turnInRotation: number) => ({
                                        id,
                                        turnInRotation,
                                        name: details?.find(
                                          (detail) => detail.id === id
                                        )?.deliveryCompany?.name,
                                      })
                                    ),
                                  });
                                }}
                              >
                                <Droppable droppableId={rotation.id!}>
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: "white",
                                        border: "1px solid #D1D5DB",
                                        borderRadius: 6,
                                      }}
                                    >
                                      {(
                                        filterDeduplicateAndSortDetails(rotation.orderDivideds)
                                          .map((detail, index) => (
                                            <Draggable
                                              key={detail?.id}
                                              draggableId={detail!.id!}
                                              index={index}
                                            >
                                              {(provided) => (
                                                <div
                                                  onClick={() => (detail.deliveryCompanyId && !selectedDetailId) && setSelectedDetailId(detail.id)}
                                                  ref={provided.innerRef}
                                                  key={detail?.id}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  {detail.deliveryCompanyId && detail.id === selectedDetailId &&
                                                    <OrderDetailManualModals
                                                      selectedDeliveryCompanyId={detail.deliveryCompanyId}
                                                      onSelectManual={(manual: Manual) => setSelectedManuals(prev => ({
                                                        ...prev,
                                                        [selectedDetailId as string]: manual
                                                      }))}
                                                      onClose={() => setSelectedDetailId(null)}
                                                    />
                                                  }
                                                  <Space
                                                    style={{
                                                      padding: 16,
                                                      borderTop:
                                                        "1px solid #F3F4F6",
                                                      justifyContent:
                                                        "space-between",
                                                      boxSizing: "border-box",
                                                      fontSize: 14,
                                                    }}
                                                  >
                                                    <Space>
                                                      <MdDragIndicator color="#D1D5DB" />
                                                      <CustomTag
                                                        style={{
                                                          height: 24,
                                                          borderRadius: 4,
                                                          padding:
                                                            "2px 6px 2px 6px",
                                                          color:
                                                            detail?.pointType ===
                                                              1
                                                              ? "#10B981"
                                                              : "#EF4444",
                                                          background:
                                                            detail?.pointType ===
                                                              1
                                                              ? "#ECFDF5"
                                                              : "#FEF2F2",
                                                          lineHeight: "20px",
                                                          fontWeight: "bolder",
                                                        }}
                                                      >
                                                        {detail?.pointType === 2
                                                          ? "積み"
                                                          : "降し"}
                                                      </CustomTag>
                                                      <span
                                                        style={{
                                                          width:
                                                            window.innerWidth >
                                                              480
                                                              ? "100%"
                                                              : 150,
                                                          display: "inline-block",
                                                        }}
                                                      >
                                                        {
                                                          detail?.deliveryCompany
                                                            ?.name
                                                        }
                                                      </span>
                                                      {selectedManuals[detail.id as string] &&
                                                        <CheckCircleOutlined
                                                          style={{ color: detail?.pointType === 1 ? "#10B981" : "#EF4444" }}
                                                        />
                                                      }
                                                    </Space>
                                                    <MdArrowForwardIos />
                                                  </Space>
                                                </div>
                                              )}
                                            </Draggable>
                                          )))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </Space>
                          );
                        }
                      )}
                    </Space>
                  );
                })}
            </div>
          )}
        </Space>
      </Space>
    </div >
  );
};


const handleAdminBulkQRPrint = async (delivererUrls: any) => {
  // 印刷用のiframeを作成
  const iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = '0';
  document.body.appendChild(iframe);

  // iframeのdocument.bodyを取得
  const docContainer = iframe.contentDocument?.body || iframe.contentWindow?.document.body as HTMLElement;
  // 印刷用のスタイルを追加
  const styleElement = document.createElement('style');
  styleElement.textContent = `
    @media print {
       @page {
        size: A4 portrait;
       }
     }
  `;
  iframe.contentDocument?.head.appendChild(styleElement);

  // 印刷用のコンテンツをiframeにレンダリング
  ReactDOM.render(await QRPrintComponent(delivererUrls), docContainer);

  setTimeout(() => {
    iframe.contentWindow?.print();
    document.body.removeChild(iframe);
  }, 100);
};

const handleBulkURLCopy = async (delivererUrls: any) => {
  // すべての非同期処理が完了するのを待つ
  const copyTexts = delivererUrls.map((delivererUrl: any, index: number) =>
    `${index > 0 ? "\n" : ""}${delivererUrl.name}\n${delivererUrl.urls.map((url: any) => `${url.order}\n${url.title}\n${url.url}`).join('\n')}`
  )
  // 結果を改行で連結してクリップボードにコピー
  const finalText = copyTexts.filter((text: any) => !!text).join('\n');
  setTimeout(() => {
    // iphoneではsetTimeOutしないとコピーが拒否される
    copyToClipboard(finalText);
  }, 500)

  // 成功メッセージを表示
  message.success("クリップボードに共有用URLを一括コピーしました。");
};
