import { PlusOutlined } from '@ant-design/icons';

interface AddRotationButtonProps {
    onClick: () => void;
}

const AddRotationButton: React.FC<AddRotationButtonProps> = ({ onClick }) => (
    <div onClick={onClick} style={{ width: "100%", height: 37, display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
        <div style={{ color: '#3730D4', height: 'fit-content', textAlign: 'center', gap: 6, display: 'flex', alignItems: 'center' }}>
            <PlusOutlined style={{ fontSize: 12 }} />
            <span style={{ fontSize: 12, fontWeight: "bold" }}>回転を追加する</span>
        </div>
    </div>
);

export default AddRotationButton;