import { Alert, Flex, Skeleton, Space, theme } from "antd";
import { GlobalStateContext } from "contexts/global_state_context";

import { Order } from "entities/order";
import { Rotation } from "entities/rotation";
import { useContext, useEffect, useState } from "react";
import { HistoryProps } from "routes/app";
import { CustomSelectBlockField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import { CalendarOutlined } from "@ant-design/icons";
import { WeekVerticalView } from "specifics/date_view";
import { DeliveriesKanban } from "./deliveries_kanban";
import { DeliveriesMaps } from "./deliveries_maps";
import { DeliveriesAssigned } from "./deliveries_assigned";
import { useFetchAllVehiclesApi } from "api/vehicle";
import dayjs from "dayjs";
import QueryString from "qs";
import { DeliveriesCalendar } from "./deliveries_calendar_modal";
import { useFetchOrdersApi } from "api/order";
import { useFetchRotationsApi } from "api/rotation";
import { useFetchAllDeliveryPartnerCompaniesApi, useFetchDeliveryPartnerCompanyDataApi } from "api/delivery_partner_company";

export enum KanbanMode {
  すべて = "all",
  自社 = "self",
  傭車 = "charter",
}

export const DeliveriesPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const listOrdersApi = useFetchOrdersApi();
  const vehiclesApi = useFetchAllVehiclesApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const listRotationsApi = useFetchRotationsApi();
  const partnerCompaniesDataApi = useFetchDeliveryPartnerCompanyDataApi();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [kanbanMode, setKanbanMode] = useState(KanbanMode.すべて);
  const { token } = theme.useToken();

  const PageTypes = ["kanban", "map", "assigned"] as const;
  type PageType = (typeof PageTypes)[number];
  const query = useQuery<{ date: string; page: PageType }>();

  const [page, setPage] = useState<PageType>("kanban");
  useEffect(() => {
    setPage(PageTypes.includes(query.page) ? query.page : "kanban");
    setSelectedDate(query.date ? new Date(query.date) : new Date());
  }, [query.date, query.page]);

  const ordersForm = useForm<Order[]>([]);
  const rotationsForm = useForm<Rotation[]>([]);

  const setDateRange = () => {
    const selectedDateOrDefault =
      selectedDate || (query.date ? new Date(query.date) : new Date());
    const date1 = dayjs(selectedDateOrDefault).startOf("week");
    const date2 = dayjs(selectedDateOrDefault).endOf("week");
    setStartDate(date1.toDate());
    setEndDate(date2.toDate());
  };

  useEffect(() => {
    // Ensure startDate, endDate, and selectedDate are not undefined before comparison
    if (startDate && endDate && selectedDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const selected = dayjs(selectedDate);

      // If selected date is out of range
      if (selected.isBefore(start) || selected.isAfter(end)) {
        // Perform your actions here when the selectedDate is out of the range.
        setDateRange();
      }
    }
  }, [selectedDate]);

  const filteredList = () => {
    const filters = `orderDetails.deliveryDate:${dayjs(startDate).format(
      "YYYY-MM-DD"
    )},${dayjs(endDate).format("YYYY-MM-DD")}`;
    const filterQuery = `?filter=${encodeURIComponent(filters)}`;
    listOrdersApi.execute(filterQuery);
  };

  const filteredRotations = () => {
    const filters = `deliveryDate:${dayjs(startDate).format(
      "YYYY-MM-DD"
    )},${dayjs(endDate).format("YYYY-MM-DD")}`;
    const filterQuery = `?filter=${encodeURIComponent(filters)}`;
    listRotationsApi.execute(filterQuery);
  };

  useEffectSkipFirst(() => {
    if (new Date(query.date) <= startDate!) {
      // query.dateがstarDate以前を指している時
      return;
    }
    filteredList();
    filteredRotations();
  }, [startDate]);

  useEffect(() => {
    setDateRange();
    vehiclesApi.execute();
    deliveryPartnerCompaniesApi.execute();
    partnerCompaniesDataApi.execute();
  }, []);
  const deliveries = (): Order[] => {
    return listOrdersApi.response.data || [];
  };

  const getDateRepresentingDelivery = (order: Order) => {
    let minLoadDate = "9999/12/31";
    order.orderDetails
      ?.filter((detail) => detail.pointType === 2)
      .every((detail) => {
        // deliveryDateがnullの時も割当予定の配車カードに含める
        if (detail.deliveryDate === null && minLoadDate === "9999/12/31") {
          minLoadDate = "";
        }
        if (
          detail.deliveryDate &&
          (minLoadDate > detail.deliveryDate || minLoadDate === "")
        ) {
          minLoadDate = detail.deliveryDate;
        }
        return true;
      });
    return minLoadDate ? dayjs(minLoadDate).format("YYYY/MM/DD") : minLoadDate;
  };

  const deliveriesToday = (): Order[] => {
    return deliveries().filter((order) => {
      const minLoadDate = getDateRepresentingDelivery(order);
      return (
        minLoadDate === dayjs(selectedDate).format("YYYY/MM/DD") ||
        minLoadDate === ""
      );
    });
  };

  const rotations = (): Rotation[] => {
    return listRotationsApi.response.data || [];
  };

  const rotationsToday = (): Rotation[] => {
    return rotations().filter((rotation) => {
      const date = rotation.deliveryDate
        ? dayjs(rotation.deliveryDate).format("YYYY/MM/DD")
        : null;
      return date === dayjs(selectedDate).format("YYYY/MM/DD") || date === null;
    });
  };

  useEffectSkipFirst(() => {
    globalState.setLoading(
      listOrdersApi.loading ||
      vehiclesApi.loading ||
      deliveryPartnerCompaniesApi.loading ||
      partnerCompaniesDataApi.loading ||
      listRotationsApi.loading
    );
    if (
      listOrdersApi.isSuccess() &&
      vehiclesApi.isSuccess() &&
      deliveryPartnerCompaniesApi.isSuccess() &&
      partnerCompaniesDataApi.isSuccess() &&
      listRotationsApi.isSuccess()
    ) {
      ordersForm.set(deliveriesToday());
      rotationsForm.set(rotationsToday());
    }
  }, [
    listOrdersApi.loading ||
    vehiclesApi.loading ||
    deliveryPartnerCompaniesApi.loading ||
    partnerCompaniesDataApi.loading ||
    listRotationsApi.loading,
    selectedDate,
  ]);

  return (
    <CustomPageHeader
      style={{
        borderBottom: `0px`,
      }}
      childrenStyle={{
        backgroundColor: token.colorWhite,
        padding: 0,
      }}
      title={"配車"}
      subTitle={
        <Skeleton loading={!selectedDate}>
          <Space size={10}>
            <div
              style={{
                fontWeight: 799,
                fontSize: 18,
              }}
            >
              積み日:
              {!selectedDate
                ? "--"
                : `${selectedDate!.getFullYear()}年${selectedDate!.getMonth() + 1
                }月`}
            </div>
            {/* <Space size={0}>
              <CustomButton
                type="text"
                style={{ padding: 0, width: 24 }}
                icon={<LeftOutlined />}
                onClick={() => {
                  selectedDate!.setDate(selectedDate!.getDate() - 7);
                  setSelectedDate(new Date(selectedDate!.getTime()));
                  setDateRange();
                  props.history.push(
                    `/operations/deliveries?${QueryString.stringify({
                      page,
                      date: dayjs(selectedDate).format("YYYY-MM-DD"),
                    })}`
                  );
                }}
              />
              <CustomButton
                type="text"
                style={{ padding: 0, width: 24 }}
                icon={<RightOutlined />}
                onClick={() => {
                  selectedDate!.setDate(selectedDate!.getDate() + 7);
                  setSelectedDate(new Date(selectedDate!.getTime()));
                  setDateRange();
                  props.history.push(
                    `/operations/deliveries?${QueryString.stringify({
                      page,
                      date: dayjs(selectedDate).format("YYYY-MM-DD"),
                    })}`
                  );
                }}
              />
            </Space> */}
          </Space>
        </Skeleton>
      }
    // extra={[
    //   <DeliveriesCalendar
    //     onOk={(e) => {
    //       props.history.push(
    //         `/operations/deliveries?${QueryString.stringify({
    //           page,
    //           date: dayjs(selectedDate).format("YYYY-MM-DD"),
    //         })}`
    //       );
    //     }}
    //     selectedDate={selectedDate}
    //     setSelectedDate={setSelectedDate}
    //     key={"calendarModal"}
    //     deliveries={listOrdersApi.response.data || []}
    //     getDateRepresentingDelivery={getDateRepresentingDelivery}
    //   />,
    // ]}
    // headerBottom={
    //   <WeekHorizontalView
    //     style={{ borderBottom: `1px solid ${token.colorBorder}` }}
    //     canHover
    //     startDate={startDate ?? new Date()}
    //     selectedDate={selectedDate ?? new Date()}
    //     setSelectedDate={(date) => {
    //       window.location.replace(
    //         `/operations/deliveries?${QueryString.stringify({
    //           page,
    //           date: dayjs(date).format("YYYY-MM-DD"),
    //         })}`
    //       );
    //     }}
    //     dateShowDot={(date) =>
    //       deliveries()
    //         .map((delivery) => getDateRepresentingDelivery(delivery))
    //         .includes(dayjs(date).format("YYYY/MM/DD"))
    //     }
    //     width={"calc(100vw - 264px)"}
    //   />
    // }
    >
      <Space
        style={{
          backgroundColor: "#F7F8FA",
          width: "100%",
          overflowX: page === "kanban" ? "hidden" : "auto",
        }}
        direction="vertical"
        size={0}
      >
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <CustomSelectBlockField
            style={{ backgroundColor: "#E6EAF2" }}
            selectItems={[
              {
                label: "配車",
                value: "kanban",
              },
              {
                label: "マップ",
                value: "map",
              },
              {
                label: "運行指示済み",
                value: "assigned",
              },
            ]}
            value={page}
            onChange={(page) =>
              props.history.push(
                `/operations/deliveries?${QueryString.stringify({
                  page,
                  date: dayjs(selectedDate).format("YYYY-MM-DD"),
                })}`
              )
            }
          />
        </Space>
        <Flex gap={0}>
          <WeekVerticalView
            style={{
              background: "white",
              borderBottom: `1px solid ${token.colorBorder}`,
              padding: "0 10px",
              alignItems: "center",
              width: "fit-content"
            }}
            canHover
            startDate={startDate ?? new Date()}
            selectedDate={selectedDate ?? new Date()}
            setSelectedDate={(date) => {
              window.location.replace(
                `/operations/deliveries?${QueryString.stringify({
                  page,
                  date: dayjs(date).format("YYYY-MM-DD"),
                })}`
              );
            }}
            dateShowDot={(date) =>
              deliveries()
                .map((delivery) => getDateRepresentingDelivery(delivery))
                .includes(dayjs(date).format("YYYY/MM/DD"))
            }
          >
            <DeliveriesCalendar
              onOk={(e) => {
                props.history.push(
                  `/operations/deliveries?${QueryString.stringify({
                    page,
                    date: dayjs(selectedDate).format("YYYY-MM-DD"),
                  })}`
                );
              }}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              key={"calendarModal"}
              deliveries={listOrdersApi.response.data || []}
              getDateRepresentingDelivery={getDateRepresentingDelivery}
              icon={<CalendarOutlined />}
            />
            {/* <Divider /> */}
            <span style={{ fontSize: 12, color: token.colorText }}>{dayjs(selectedDate).format("YYYY/MM")}</span>
          </WeekVerticalView>
          {deliveriesToday().length > 0 ? (
            <>
              {page === "kanban" && (
                <Skeleton
                  loading={
                    listRotationsApi.loading ||
                    deliveryPartnerCompaniesApi.loading ||
                    partnerCompaniesDataApi.loading
                  }
                >
                  <DeliveriesKanban
                    history={props.history}
                    form={ordersForm}
                    vehicles={vehiclesApi.response.data || []}
                    deliveryPartnerCompanies={
                      deliveryPartnerCompaniesApi.response.data || []
                    }
                    rotations={rotationsToday()}
                    selectedDate={selectedDate ?? new Date()}
                    onListOrders={() => filteredList()}
                    onListRotations={() => filteredRotations()}
                    mode={kanbanMode}
                    setMode={setKanbanMode}
                    partnerCompanyData={JSON.parse(
                      partnerCompaniesDataApi.response.data.data || "{}"
                    )}
                  />
                </Skeleton>
              )}
              {page === "map" && (
                <DeliveriesMaps
                  history={props.history}
                  vehicles={vehiclesApi.response.data || []}
                  deliveryPartnerCompanies={
                    deliveryPartnerCompaniesApi.response.data || []
                  }
                  form={rotationsForm}
                  orders={deliveriesToday()}
                  selectedDate={selectedDate!}
                />
              )}
              {page === "assigned" && (
                <Skeleton
                  loading={
                    partnerCompaniesDataApi.loading || listRotationsApi.loading
                  }
                >
                  <DeliveriesAssigned
                    selectedDateString={
                      dayjs(selectedDate).format("YYYY/MM/DD") || ""
                    }
                    rotations={rotationsToday().filter(
                      (rotation) => rotation.isAssigned
                    )}
                    deliveryPartnerCompanies={
                      deliveryPartnerCompaniesApi.response.data || []
                    }
                    vehicles={vehiclesApi.response.data || []}
                    onRotation={() => filteredRotations()}
                    onDraft={() => partnerCompaniesDataApi.execute()}
                    partnerCompanyData={JSON.parse(
                      partnerCompaniesDataApi.response.data.data || "{}"
                    )}
                  />
                </Skeleton>
              )}
            </>
          ) : (
            <div>
              <Alert message="この日の配送データはありません。" />
            </div>
          )}
        </Flex>
      </Space>
    </CustomPageHeader>
  );
};
