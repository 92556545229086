import { Space, theme } from "antd";

import dayjs from "dayjs";
import { ReactNode, useEffect, useState } from "react";
import { CSSProperties } from "styled-components";
import { dayOfWeekStrJP } from "utils/util";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { CustomButton } from "./button";

export type WeekViewProps = {
  startDate: Date;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  style?: CSSProperties;
  dateStyle?: CSSProperties;
  width?: string | number;
  canHover?: boolean;
  dateShowDot?: (date: Date) => boolean;
  children?: ReactNode;
};

export const WeekHorizontalView = (props: WeekViewProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  useEffect(() => {
    setStartDate(props.startDate);
  }, [props.startDate]);
  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);
  return (
    <Space
      style={{
        width: "100%",
        paddingLeft: 32,
        paddingRight: 32,
        alignItems: "flex-start",
        ...props.style,
      }}
      size={0}
    >
      {Array(7)
        .fill(0)
        .map((_, i) => {
          const date = new Date(startDate.getTime());
          date.setDate(date.getDate() + i);
          return (
            <div
              key={`timeIs${date.getTime()}`}
              onClick={() => {
                props.setSelectedDate(date);
              }}
            >
              <DateView
                canHover
                date={date}
                selectedDate={selectedDate}
                totalWidth={props.width}
                style={props.dateStyle}
                showDot={props.dateShowDot && props.dateShowDot(date)}
              />
            </div>
          );
        })}
    </Space>
  );
};

export const WeekVerticalView = (props: WeekViewProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  useEffect(() => {
    setStartDate(props.startDate);
  }, [props.startDate]);
  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);
  return (
    <Space
      style={{
        width: "100%",
        paddingLeft: 32,
        paddingRight: 32,
        alignItems: "flex-start",
        ...props.style,
      }}
      direction="vertical"
      size={0}
    >
      <CustomButton
        type="text"
        style={{ padding: 0, width: 24 }}
        icon={<UpOutlined />}
        onClick={() => {
          const newDate = new Date(selectedDate);
          newDate.setDate(newDate.getDate() - 7);
          setSelectedDate(newDate);
          props.setSelectedDate(newDate);
        }}
      />
      {props.children}
      {Array(7)
        .fill(0)
        .map((_, i) => {
          const date = new Date(startDate.getTime());
          date.setDate(date.getDate() + i);
          return (
            <div
              key={`timeIs${date.getTime()}`}
              onClick={() => {
                props.setSelectedDate(date);
              }}
            >
              <DateView
                canHover
                date={date}
                selectedDate={selectedDate}
                totalWidth={props.width}
                style={{
                  ...props.dateStyle,
                  flexDirection: "row-reverse",
                  background: "white",
                }}
                showDot={props.dateShowDot && props.dateShowDot(date)}
              />
            </div>
          );
        })}
      <CustomButton
        type="text"
        style={{ padding: 0, width: 24 }}
        icon={<DownOutlined />}
        onClick={() => {
          const newDate = new Date(selectedDate);
          newDate.setDate(newDate.getDate() + 7);
          setSelectedDate(newDate);
          props.setSelectedDate(newDate);
        }}
      />
    </Space>
  );
};

export const DateView = (props: {
  date: Date;
  selectedDate: Date;
  totalWidth?: string | number;
  circleViewProps?: CircleViewProps;
  style?: CSSProperties;
  canHover?: boolean;
  showDot?: boolean;
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState<boolean>(false);
  const { date, selectedDate, totalWidth, style } = props;

  const isSelected = () =>
    dayjs(date).format("YYYY/MM/DD") ===
    dayjs(selectedDate).format("YYYY/MM/DD");
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        height: 71,
        width: `calc(${totalWidth} / 7)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 400,
        // borderBottom: isSelected()
        //   ? `1px solid ${token.colorPrimary}`
        //   : undefined,
        borderRadius: 16,
        borderBottomLeftRadius: isSelected() ? 0 : 16,
        borderBottomRightRadius: isSelected() ? 0 : 16,
        cursor: props.canHover ? "pointer" : "auto",
        background: props.canHover && hover ? token.colorBgBase : "inherit",
        ...style,
      }}
    >
      <div style={{ height: 17, marginBottom: 2 }}>
        <span
          style={{
            width: 14,
            marginTop: 0,
            verticalAlign: "top",
            color: isSelected() ? token.colorPrimary : token.colorText,
          }}
        >
          {dayOfWeekStrJP[date.getDay() % 7]}
        </span>
      </div>
      <CircleView isSelected={!!isSelected()} canHover={props.canHover}>{date.getDate()}</CircleView>
    </div>
  );
};

type CircleViewProps = {
  children?: ReactNode;
  isSelected?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  selectedColorBg?: string;
  notSelectedColorBg?: string;
  selectedColorText?: string;
  notSelectedColorText?: string;
  canHover?: boolean;
};

export const CircleView = (props: CircleViewProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const { token } = theme.useToken();

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: "flex",
        alignItems: "flex-start",
        padding: 6,
        height: 32,
        width: 32,
        gap: "10px",
        background: props.isSelected
          ? props.selectedColorBg ?? token.colorPrimary
          : props.notSelectedColorBg ?? "inherit",
        borderRadius: "32px",
        textAlign: "center",
        verticalAlign: "middle",
        cursor: props.canHover ? "pointer" : "auto",
      }}
      onClick={props.onClick}
    >
      <span
        style={{
          width: 20,
          height: 20,
          lineHeight: "16px",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: 16,
          marginTop: 2,
          letterSpacing: "-0.01rem",
          color: props.isSelected
            ? props.selectedColorText ?? token.colorWhite
            : props.notSelectedColorText ?? token.colorText,
          flexGrow: 0,
          order: 0,
          ...props.style,
        }}
      >
        {props.children}
      </span>
    </div>
  );
};
