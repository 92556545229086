import React, { ReactNode, CSSProperties, ForwardedRef, useState } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem, Item } from "./sortable_item";

import AddRotationButton from "./add_rotation";
import { Button, Checkbox, Col, Divider, Flex, Input, Progress, Row, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ItemData } from "../deliveries_kanban";
import { OrderForm, OrderStatus } from "entities/order";

interface ContainerProps {
  children: ReactNode;
  driver?: boolean;
  style?: CSSProperties;
  driverTitle?: () => JSX.Element;
  index?: number;
}

const Container = React.forwardRef(
  (props: ContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, driver, style = {}, index } = props;

    const containerStyle: CSSProperties = {
      padding: !driver ? 10 : 0,
      flex: 1,
      display: "flex",
      justifyContent: "right",
      gap: !driver ? 10 : 0,
      minHeight: 50,
      // maxHeight: "calc(-438px + 100vh)",
      flexDirection: "column",
    };

    return (
      <div style={{
        backgroundColor: "#F9FAFB",
        borderRight: driver && parent ? "1px solid #E6EAF2" : "none",
        borderLeft: driver && parent && index === 0 ? "1px solid #E6EAF2" : "none",
      }}>
        {driver && props.driverTitle && <props.driverTitle />}
        <div
          ref={ref}
          style={{
            ...containerStyle,
            ...style,
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);

Container.displayName = "Container";

export { Container };

export type driverOption = {
  value?: string;
  label?: string;
};

interface SortableContainerProps {
  getItems: (id?: string) => ItemData[];
  id: string;
  isDragging?: boolean;
  driver?: boolean;
  driverUnassigned?: boolean;
  isChartered?: boolean;
  driverTitle?: () => JSX.Element;
  driverOptions?: driverOption[];
  partnerVehicleOptions?: driverOption[];
  style?: CSSProperties;
  handlePosition: string;
  maxWeight?: number;
  maxPallets?: number;
  fare?: number;
  index: number;
  parent?: string;
  isChecked?: boolean;
  isAssigned?: boolean;
  onAddRotation?: (id: string) => void;
  onSave: (order: OrderForm) => void;
  onSelected?: (rotationId: string, driverId: string) => void;
  onDeleteRotation: (rotationId: string) => void;
  onCheck?: (rotationId: string, isChecked: boolean) => void;
  onDeleteDivided: (dividedId: string) => void;
  onDeleteOrder: (id: string) => void;
}

export const SortableContainer: React.FC<SortableContainerProps> = ({
  getItems,
  id,
  isDragging,
  driver,
  driverUnassigned,
  isChartered,
  onAddRotation,
  index,
  parent,
  isChecked,
  isAssigned,
  driverTitle,
  driverOptions,
  partnerVehicleOptions,
  onSave,
  onSelected,
  onDeleteRotation,
  onCheck,
  onDeleteDivided,
  onDeleteOrder,
  maxWeight,
  maxPallets,
  fare,
}) => {
  const isDropDisabled = isChartered && id.includes("child");// 傭車かつドライバー未選択時はカードをアサインできないように
  const { isOver, setNodeRef } = useDroppable({
    id,
    disabled: isDropDisabled
  });
  const [filter, setFilter] = useState("");
  const items = getItems(id);
  const itemIds = items.map((item) => item.id);
  const style: CSSProperties = {
    backgroundColor: driver ? "#F9FAFB" : "#F0F2F6",
    minWidth: driver ? "210px" : "0",
    borderBottom: !driver ? "1px solid #E6EAF2" : "none",
    height: isDragging && !isDropDisabled ? "400px" : "initial", // ドライバーレーンにoverしている時、中の回転の高さを規定する
    opacity: isDropDisabled ? 0.5 : 1, // disabledのときに透明度を下げる
    cursor: isDropDisabled ? "not-allowed" : "auto",// disabledのときにポインターイベントを無効化
  };

  if (isOver) {
    console.log("is over", id);
  }

  const onSelect = (driverId: string) => {
    if (onSelected) {
      onSelected(id, driverId);
    }
  };

  const assignedWeight = (id: string) => {
    const weight = Number(
      getItems(id).reduce((total, item) => {
        return total + item.assignedWeight!;
      }, 0)
    );
    return weight ? Number(weight.toFixed(1)) : 0;
  };

  const assignedPallets = (id: string) => {
    const pallet = Number(
      getItems(id).reduce((total, item) => {
        return total + item.assignedPallets!;
      }, 0)
    );
    return pallet ? Number(pallet.toFixed(1)) : 0;
  };

  const getSummary = () => {
    const parentPartnerCompanyIds = getItems()
      .filter(item => item.isChartered)
      .map(item => item.id);

    let totalLoadingRate = 0;
    let totalPalletRate = 0;
    let totalFare = 0;
    let totalVehicles = 0;
    parentPartnerCompanyIds.forEach(partnerCompanyId => {
      const partnerVehicles = getItems(partnerCompanyId);
      totalVehicles += partnerVehicles.length;

      partnerVehicles.forEach(vehicle => {
        totalLoadingRate += vehicle.loadingRate || 0;
        totalPalletRate += vehicle.palletRate || 0;
        totalFare += vehicle.fare || 0;
      });
    });

    const loadingRate = Math.floor(totalLoadingRate / totalVehicles);
    const palletRate = Math.floor(totalPalletRate / totalVehicles);

    return { 割当済み: totalVehicles, 稼働率: loadingRate, パレット使用率: palletRate, 運賃合計: totalFare };
  };

  return driver && driverUnassigned ? (
    // 未割当レーン
    <SortableItem
      id={id}
      handlePosition="top"
      driver={driver}
      driverUnassigned={driverUnassigned}
    >
      <Container
        ref={setNodeRef}
        driver={driver}
        style={style}
        driverTitle={driverTitle}
      >
        <SortableContext
          items={itemIds}
          strategy={driver ? verticalListSortingStrategy : horizontalListSortingStrategy}
        >
          {/* アサイン前のカードの溜まり */}
          <div
            style={{
              maxHeight: "calc(100vh - 510px)",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: 10,
            }}
          >
            <Input
              style={{ fontSize: 12 }}
              placeholder="受注番号or行先名称で検索"
              onChange={(e) => setFilter(e.target.value)}
            />
            {items
              .filter(item => item.order?.status !== OrderStatus.Assigned)
              .filter(item =>
                !filter ||
                item.order?.orderNo?.includes(filter) ||
                item.order?.orderDetails?.some(detail => detail.deliveryCompany?.name?.includes(filter))
              )
              .map(item => {
                const child = (
                  <Item
                    item={item}
                    getItems={getItems}
                    driverOptions={driverOptions}
                    partnerVehicleOptions={partnerVehicleOptions}
                    onDeleteDivided={onDeleteDivided}
                    onDeleteOrder={onDeleteOrder}
                    onSave={onSave}
                  />
                );
                if (item.container) {
                  return;
                } else {
                  return (
                    <SortableItem key={item.id} id={item.id}>
                      {child}
                    </SortableItem>
                  );
                }
              })}
          </div>
          {/* サマリー */}
          <Flex vertical>
            <Divider style={{ margin: 0 }} />
            <Flex
              vertical
              gap={8}
              style={{ backgroundColor: "#F7F8FA" }}
            >
              {Object.entries(getSummary()).map(([label, value]) => {
                switch (label) {
                  case "割当済み":
                    return (
                      <Flex vertical>
                        <p
                          key={label}
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            backgroundColor: "white",
                            width: "100%",
                            textAlign: "center",
                            margin: 0,
                            padding: "15px 0",
                          }}
                        >
                          {label} :（{value}）
                        </p>
                        <Divider style={{ margin: 0 }} />
                      </Flex>
                    );
                  case "運賃合計":
                    return (
                      <Row align="middle" key={label} style={{ padding: "0 12px" }}>
                        <Col span={10} style={{ fontSize: 11, color: "#5C6171" }}>
                          {label}
                        </Col>
                        <Col>
                          <span
                            style={{
                              borderLeft: "1px solid #CDD2DC",
                              margin: "0 8px"
                            }}
                          />
                        </Col>
                        <Col span={11}>
                          <Flex vertical>
                            <div>
                              <span style={{ fontSize: 14, fontWeight: "bold" }}>
                                {value}
                              </span>
                              <span style={{ fontSize: 11 }}>円</span>
                            </div>
                          </Flex>
                        </Col>
                      </Row>
                    );
                  default:
                    return (
                      <Row align="middle" key={label} style={{ padding: "0 12px" }}>
                        <Col span={10} style={{ fontSize: 11, color: "#5C6171" }}>
                          {label}
                        </Col>
                        <Col>
                          <span
                            style={{
                              borderLeft: "1px solid #CDD2DC",
                              margin: "0 8px"
                            }}
                          />
                        </Col>
                        <Col
                          span={3}
                          style={{
                            color: value > 100 ? "#ff4d4f" : "initial"
                          }}
                        >
                          <span
                            style={{
                              fontSize: value > 100 ? 10 : 14,
                              fontWeight: "bold"
                            }}
                          >
                            {value}
                          </span>
                          <span style={{ fontSize: 11 }}>%</span>
                        </Col>
                        <Col span={8}>
                          <Progress
                            style={{ margin: "0 0 0 8px" }}
                            showInfo={false}
                            percent={value}
                            strokeColor={
                              value > 100 ? "#ff4d4f" : "#A9A8FF"
                            }
                          />
                        </Col>
                      </Row>
                    );
                }
              })}
            </Flex>
          </Flex>
          {/* 未割当内の回転 */}
          {items
            .filter((item) => item.container)
            .map((item, index) => {
              return (
                <>
                  <SortableContainer
                    key={item.id}
                    id={item.id}
                    isDragging={isOver}
                    index={index}
                    parent={item.parent}
                    getItems={getItems}
                    driver={item.driver}
                    driverOptions={driverOptions}
                    partnerVehicleOptions={partnerVehicleOptions}
                    handlePosition="top"
                    maxWeight={maxWeight}
                    onSave={onSave}
                    onSelected={onSelected}
                    onDeleteRotation={onDeleteRotation}
                    onDeleteDivided={onDeleteDivided}
                    onDeleteOrder={onDeleteOrder}
                  />
                </>
              );
            })}
        </SortableContext>
      </Container>
    </SortableItem>
  ) : (
    // ドライバー、回転
    <SortableItem
      id={id}
      handlePosition="top"
      driver={driver}
      driverUnassigned={driverUnassigned}
      isCharteredItem={isChartered}
    >
      <Container
        ref={setNodeRef}
        driver={driver}
        style={style}
        driverTitle={driverTitle}
        index={index}
      >
        {/* 回転のヘッダー */}
        {!driver && (
          <div style={{ position: "relative", borderBottom: "1px solid #E6EAF2" }}>
            {
              <Button
                onClick={() => onDeleteRotation(id)}
                style={{
                  position: "absolute",
                  border: 0,
                  top: 0,
                  right: 0,
                  background: "transparent",
                  cursor: "pointer",
                }}
                icon={
                  !isDropDisabled && <CloseOutlined style={{ fontSize: 14, color: "#919eab" }} />
                }
              />
            }
            <p
              style={{
                fontSize: 11,
                fontWeight: "bold",
                color: "#6B7280",
                margin: 0,
              }}
            >
              回転{index + 1}
            </p>
            <Flex vertical>
              {maxWeight !== undefined && (
                <>
                  {!isChartered && (
                    <Select
                      value={parent !== "undefined" ? parent : "未割当"}
                      style={{ margin: "7px 0" }}
                      options={driverOptions}
                      onSelect={onSelect}
                    />
                  )}
                  <table
                    style={{
                      color: "#6B7280",
                      width: "100%",
                      fontSize: 13,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            verticalAlign: "top",
                            textAlign: "left",
                          }}
                        >
                          最大積載量
                        </td>
                        <td
                          style={{
                            verticalAlign: "top",
                            whiteSpace: "nowrap",
                          }}
                        >
                          :
                        </td>
                        <td>{maxWeight}t</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            verticalAlign: "top",
                            textAlign: "left",
                          }}
                        >
                          現在の総積載量
                        </td>
                        <td
                          style={{
                            verticalAlign: "top",
                            whiteSpace: "nowrap",
                          }}
                        >
                          :
                        </td>
                        <td>
                          {assignedWeight(id)}kg
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            verticalAlign: "top",
                            textAlign: "left",
                          }}
                        >
                          現在の積載率
                        </td>
                        <td
                          style={{
                            verticalAlign: "top",
                            whiteSpace: "nowrap",
                          }}
                        >
                          :
                        </td>
                        <td>
                          {maxWeight > 0
                            ? Math.round(
                              (assignedWeight(id) / (maxWeight! * 1000)) * 100
                            )
                            : 0
                          }%
                        </td>
                      </tr>
                      {/* パレット数(使用/最大) */}
                      {isChartered && (
                        <tr>
                          <td>パレット数</td>
                          <td>:</td>
                          <td>{assignedPallets(id)}/{maxPallets || 0}
                          </td>
                        </tr>
                      )}
                      {/* 運賃(運賃/単価) */}
                      {isChartered && (
                        <tr>
                          <td>運賃</td>
                          <td>:</td>
                          <td>
                            {fare || 0}円
                          </td>
                        </tr>
                      )}
                      {isChartered && (
                        <tr>
                          <td>単価</td>
                          <td>:</td>
                          <td>
                            {fare && assignedWeight(id) > 0
                              ? Math.round(fare / assignedWeight(id))
                              : 0
                            }円/kg
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </Flex>
            {parent !== "undefined" && (
              <Checkbox
                checked={isAssigned || isChecked}
                disabled={isAssigned}
                onChange={(e) => onCheck!(id, e.target.checked)}
                style={{
                  color: "black",
                  fontSize: 11,
                }}
              >
                {isAssigned ? "運行指示済み" : "運行指示"}
              </Checkbox>
            )}
          </div>
        )}
        {/* ドライバー、回転 */}
        <SortableContext
          items={itemIds}
          strategy={
            driver ? verticalListSortingStrategy : horizontalListSortingStrategy
          }
        >
          <div
            style={{
              maxHeight: driver && ((isChartered && parent) || !isChartered) ? "calc(100vh - 400px)" : "auto",
              overflowY: driver ? "auto" : "hidden",
              display: "flex",
              flexDirection: driver && !parent ? "row" : "column",
              gap: !driver ? 10 : 0,
            }}
          >
            {items.map((item, index) => {
              const child = (
                <Item
                  item={item}
                  getItems={getItems}
                  isChartered={isChartered}
                  driverOptions={driverOptions}
                  partnerVehicleOptions={partnerVehicleOptions}
                  onDeleteDivided={onDeleteDivided}
                  onDeleteOrder={onDeleteOrder}
                  onSave={onSave}
                />
              );

              if (item.container) {
                // ドライバー・回転
                return (
                  <>
                    <SortableContainer
                      key={item.id}
                      id={item.id}
                      isDragging={isOver}
                      index={index}
                      parent={item.parent}
                      isChartered={isChartered}
                      isChecked={item.isChecked}
                      isAssigned={item.isAssigned}
                      getItems={getItems}
                      driver={item.driver}
                      driverTitle={item.title}
                      driverOptions={driverOptions}
                      partnerVehicleOptions={partnerVehicleOptions}
                      handlePosition="top"
                      maxWeight={maxWeight}
                      maxPallets={maxPallets}
                      fare={fare}
                      onSave={onSave}
                      onSelected={onSelected}
                      onDeleteRotation={onDeleteRotation}
                      onDeleteDivided={onDeleteDivided}
                      onDeleteOrder={onDeleteOrder}
                      onCheck={onCheck}
                      onAddRotation={onAddRotation}
                    />
                  </>
                );
              } else {
                // 配車カード
                return (
                  <SortableItem key={item.id} id={item.id}>
                    {child}
                  </SortableItem>
                );
              }
            })}
          </div>
          {!driver &&
            <div style={{ height: 150, border: "1px dashed #E6EAF2", borderRadius: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
              カードを割り当てる
            </div>
          }
        </SortableContext>
        {driver && parent && onAddRotation && <AddRotationButton onClick={() => onAddRotation(id)} />}
      </Container>
    </SortableItem>
  );
};
