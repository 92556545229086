import { useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  sortableKeyboardCoordinates,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableContainer, Container } from "./kanban_container/container";
import { Item } from "./kanban_container/sortable_item";
import {
  Order,
  OrderDivided,
  OrderForm,
  OrderStatus,
} from "entities/order";
import { Rotation } from "entities/rotation";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { Vehicle, VehicleType } from "entities/vehicle";
import { HistoryProps } from "routes/app";
import { Button, Checkbox, Col, Divider, Flex, Input, Popover, Progress, Row, Select, Space, message } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import {
  usePostRotationApi,
  useDeleteRotationApi,
  usePostRotationInstructionsApi,
} from "api/rotation";
import {
  useDeleteOrderDividedApi,
  useEditOrderApi,
  useDeleteOrderApi,
} from "api/order";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEditVehicleApi } from "api/vehicle";
import { AssignedPartnerCompanyDataForm, DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { CustomSelectBlockField } from "specifics/input";
import { KanbanMode } from "./deliveries_page";
import { DeliveryPartnerVehicle } from "entities/delivery_partner_vehicle";
import { ReorderModal } from "specifics/reorder_modal";
import { ColumnType } from "antd/es/table";
import { LocalStorageManager } from "utils/local_storage_manager";

export interface ItemData {
  id: string;
  container?: boolean;
  driver?: boolean;
  parent?: string;
  title?: () => JSX.Element; //ドライバー
  name?: string; //ドライバー（運送会社）
  userId?: string; //ドライバー
  maxWeight?: number; //ドライバー
  maxPallets?: number; //ドライバー（運送会社車両）
  fare?: number; //ドライバー（運送会社車両）
  loadingRate?: number; // ドライバー（運送会社車両）の積載率
  palletRate?: number; // ドライバー（運送会社車両）のパレット率
  farePerKg?: number; // ドライバー（運送会社車両）の重量あたり運賃
  isChartered?: boolean; //ドライバー
  isUnassigned?: boolean; //ドライバー
  isDropDisabled?: boolean;//ドライバー
  rotationTurn?: number; //回転
  isChecked?: boolean; //回転
  isAssigned?: boolean; //回転
  order?: Order; //配車カード
  orderDivided?: OrderDivided; //配車カード
  assignedWeight?: number; //配車カード
  assignedQuantity?: number; //配車カード
  assignedPallets?: number; //配車カード
  isNew?: boolean; // 配車カード
  columnKey?: string;
}

interface DeliveriesKanbanState {
  items: ItemData[];
}

const wrapperStyle: React.CSSProperties = {
  display: "flex",
  gap: 10,
  overflowX: "auto",
};

export const DeliveriesKanban = (
  props: {
    form: Form<Order[]>;
    onListOrders: () => void;
    onListRotations: () => void;
    vehicles: Vehicle[];
    deliveryPartnerCompanies: DeliveryPartnerCompany[];
    rotations: Rotation[];
    selectedDate: Date;
    mode: KanbanMode;
    setMode: (mode: KanbanMode) => void;
    partnerCompanyData?: AssignedPartnerCompanyDataForm;
  } & HistoryProps
) => {
  const deliveriesOwn = props.form.object?.filter(
    // 自社のdelivery一覧
    (o: Order) => o.charterType === 0
  );

  const postRotationApi = usePostRotationApi();
  const postRotationInstructionsApi = usePostRotationInstructionsApi();
  const deleteRotationApi = useDeleteRotationApi();
  const deleteOrderDividedApi = useDeleteOrderDividedApi();
  const editOrderApi = useEditOrderApi();
  const deleteOrderApi = useDeleteOrderApi();
  const editVehicleApi = useEditVehicleApi();

  const vehicleForm = useForm<Vehicle>({});
  const [shouldExecuteVehicleApi, setShouldExecuteVehicleApi] = useState(false);

  useEffect(() => {
    console.log("orders,rotations loading");
    if (props.form.isEmpty()) {
      return;
    }
    const drivers = getData().drivers;

    let rotations = props.rotations.map((rotation) => {
      return {
        id: rotation.id ? rotation.id : "",
        container: true,
        driver: false,
        isAssigned: rotation.isAssigned,
        parent: rotation.partnerCompanyId ? rotation.partnerCompanyId + rotation.vehicleId : rotation.vehicleId || "undefined",
        rotationTurn: rotation.rotationTurn,
      };
    });
    // 回転のないdriverに空の１回転目を付与
    const rotationDriverIds = rotations.map((rotation) => rotation.parent);
    rotations = [
      ...rotations!,
      ...props.vehicles
        .filter((vehicle) => !rotationDriverIds.includes(vehicle.id as string))
        .map((vehicle, index) => {
          return {
            id: `${vehicle.id}child`,
            container: true,
            driver: false,
            parent: vehicle.id as string,
            rotationTurn: 0,
            isAssigned: false,
          };
        }),
      // ...props.deliveryPartnerCompanies
      //   .filter((partnerCompany) => !rotationDriverIds.includes(partnerCompany.id as string))
      //   .map((partnerCompany, index) => {
      //     return {
      //       id: `${partnerCompany.id}child`,
      //       container: true,
      //       driver: false,
      //       parent: partnerCompany.id as string,
      //       rotationTurn: 0,
      //       isAssigned: false,
      //     };
      //   }),
    ];
    const orderDivideds = props.rotations.reduce((accumulated, rotation) => {
      if (rotation.orderDivideds) {
        return accumulated.concat(rotation.orderDivideds);
      }
      return accumulated;
    }, [] as OrderDivided[]);
    const orderDividedObj: { [x: string]: OrderDivided[] } = {};
    let orderDividedItems = orderDivideds?.map((orderDivided) => {
      const parentRotation = rotations?.find(
        (rotation) => rotation.id === orderDivided.rotationId
      );
      const item: ItemData = {
        id: orderDivided.id ? orderDivided.id : "",
        container: false,
        driver: false,
        parent: orderDivided.rotationId,
        order: props.form.object.find(
          (order) => order.id === orderDivided.orderId
        ),
        orderDivided: {
          ...orderDivided,
          driverName:
            parentRotation?.parent !== "undefined"
              ? getDriver(parentRotation!.parent!)
                ? getDriver(parentRotation!.parent!)!.name
                : parentRotation!.parent!
              : "未割当",
          rotationTurn: parentRotation?.rotationTurn,
        },
      };
      Object.prototype.hasOwnProperty.call(
        orderDividedObj,
        orderDivided.orderId!
      )
        ? orderDividedObj[orderDivided.orderId!].push(item.orderDivided!)
        : (orderDividedObj[orderDivided.orderId!] = [item.orderDivided!]);
      return item;
    });
    const assignedWeight = (item: ItemData): number => {
      if (item.order?.assignedWeight && !item?.orderDivided?.orderDetails) {
        return item.order!.assignedWeight!;
      } else if (!item?.orderDivided?.orderDetails) {
        return 0;
      }

      const weight = item.orderDivided.orderDetails
        .filter((detail) => detail.pointType === 2)
        .reduce((total, detail) => total + (Number(detail.loadWeight) || 0), 0);

      item.assignedWeight = weight; // 同じ値をitem.assignedWeightに追加
      return weight;
    };

    const assignedQuantity = (item: ItemData): number => {
      // 回転内の配車カードか未割り当てないの配車カード
      const quantity = (item.orderDivided?.orderDetails || item.order?.orderDivideds?.flatMap(divided => divided.orderDetails))
        ?.filter((detail) => detail?.pointType === 2)
        ?.reduce((total, detail) => total + (Number(detail?.quantity) || 0), 0);
      item.assignedQuantity = quantity; // 同じ値をitem.assignedQuantityに追加
      return quantity || 0;
    };

    const assignedPallets = (item: ItemData): number => {
      // const pallets = item.orderDivided?.orderDetails
      //   ?.filter((detail) => detail.pointType === 2)
      //   ?.reduce((total, detail) => total + (Number(detail.loadPallets) || 0), 0);

      // パレット数をdividedごとではなく、order全体として扱う
      const pallets = item.order?.orderDetails
        ?.filter((detail) => detail.pointType === 2)
        ?.reduce((total, detail) => total + (Number(detail.loadPallets) || 0), 0);

      item.assignedPallets = pallets; // 同じ値をitem.assignedPalletsに追加
      return pallets || 0;
    };

    const checkIsNew = (item: ItemData): boolean => {
      if (!item.order && !item.orderDivided) {
        return false;
      }
      const orderUpdatedAt = item.order?.updatedAt;
      const orderDividedUpdatedAt = item.orderDivided?.updatedAt;

      const yesterday = dayjs().subtract(1, 'day');

      const isOrderUpdated = orderUpdatedAt && dayjs(orderUpdatedAt).isAfter(yesterday);
      const isOrderDividedUpdated = orderDividedUpdatedAt && dayjs(orderDividedUpdatedAt).isAfter(yesterday);

      // order編集時に同じ回転のorderDividedも編集されるので、条件複雑に
      return !!((isOrderUpdated && isOrderDividedUpdated) || isOrderUpdated);
    }

    // 回転内の配車カード
    orderDividedItems = orderDividedItems?.map((item) => {
      if (item.order) {
        return {
          ...item,
          order: {
            ...item.order,
            orderDivideds: orderDividedObj[item.order!.id!],
          },
          assignedWeight: assignedWeight(item),
          assignedQuantity: assignedQuantity(item),
          assignedPallets: assignedPallets(item),
          isNew: checkIsNew(item)
        };
      } else {
        return { ...item };
      }
    });
    // 未割当(or傭車)レーンにいる配車カード
    const unAssignedOrders = props.form.object?.map((order: Order) => {
      const item: ItemData = {
        id: order.id ? order.id : "",
        container: false,
        driver: false,
        parent: "undefined",
        order: {
          ...order,
          orderDivideds: orderDividedObj[order.id!],
        },
      };
      return {
        ...item,
        assignedWeight: assignedWeight(item),
        assignedQuantity: assignedQuantity(item),
      };
    });

    setData({
      items: [
        ...drivers,
        ...unAssignedOrders,
        ...orderDividedItems!,
        ...rotations!,
      ],
    });
  }, [props.form, props.vehicles, props.rotations]);

  useEffect(() => {
    if (shouldExecuteVehicleApi) {
      editVehicleApi.execute(vehicleForm);
      setShouldExecuteVehicleApi(false);
    }
  }, [shouldExecuteVehicleApi]);

  useEffectSkipFirst(() => {
    if (
      postRotationApi.isSuccess() ||
      postRotationInstructionsApi.isSuccess() ||
      editOrderApi.isSuccess() ||
      deleteRotationApi.isSuccess() ||
      deleteOrderDividedApi.isSuccess() ||
      deleteOrderApi.isSuccess()
    ) {
      console.log("listing orders,rotations");
      const timeoutId = setTimeout(() => {
        // 更新->ローディングのタイミングにするため150ms待つ
        props.onListOrders();
        props.onListRotations();
      }, 150);
      return () => clearTimeout(timeoutId); // リーンアップ関数でタイムアウトをクリア
    }
  }, [
    postRotationApi.loading,
    postRotationInstructionsApi.loading,
    editOrderApi.loading,
    deleteRotationApi.loading,
    deleteOrderDividedApi.loading,
    deleteOrderApi.loading,
  ]);

  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5, // 5px ドラッグした時にソート機能を有効にする
      },
    })
  );

  const gendriver = (vehicle?: Vehicle): ItemData => {
    // vehicleがundefined <=> 未割当
    const currentTotalWeight = (id: string): number => {
      const nestedArrays = getItems(id).map((item) => getItems(item.id));
      const itemAry = nestedArrays.flat();
      const totalWeight = Number(
        itemAry.reduce((acc, item) => acc + (item.assignedWeight || 0), 0)
      );
      return totalWeight ? Number(totalWeight.toFixed(1)) : 0;
    };

    const loadingTooMuch: boolean =
      currentTotalWeight(vehicle?.id || "undefined") >
      (vehicle?.maxWeight || 0);

    const title = (id?: string) => (id ?
      <div
        style={{
          padding: 7,
        }}
      >
        <p
          style={{
            fontSize: 14,
            fontWeight: "bold",
            color: "#6B7280",
            width: "100%",
            textAlign: "left",
            margin: 0,
            marginBottom: 3,
          }}
        >
          {vehicle?.user?.name}（車格:{vehicle?.weight}t、最大積載量:{vehicle?.maxWeight || "--"}t）
        </p>
        <Checkbox
          onChange={(e) =>
            onCheckAllRotations(vehicle!.id!, e.target.checked)
          }
          style={{
            color: "black",
          }}
        >
          すべての運行指示を選択
        </Checkbox>
        <Divider style={{ margin: "20px 0 8px 0" }} />
      </div>
      :
      <div>
        <p
          style={{
            fontSize: 16,
            fontWeight: "bold",
            backgroundColor: "white",
            width: "100%",
            textAlign: "center",
            margin: 0,
            padding: "15px 0",
          }}
        > 未割当:
          {props.form.object.filter(
            (order) => order.status !== OrderStatus.Assigned
          ).length
          }件
        </p>
        <Divider style={{ margin: "0 0 8px 0" }} />
      </div>
    );

    return {
      id: vehicle?.id || "undefined",
      container: true,
      driver: true,
      maxWeight: vehicle?.maxWeight,
      userId: vehicle?.userId,
      isUnassigned: !vehicle?.id,
      title: () => title(vehicle?.id)
    };
  };

  const genPartnerCompany = (partnerCompany: DeliveryPartnerCompany): ItemData[] => {
    // 運送会社の各車両
    const assignedRotations = props.rotations.filter(rotation => rotation.partnerCompanyId === partnerCompany.id).sort((a, b) => dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1);
    const assignedVehicleIds = assignedRotations
      .map(rotation => rotation.vehicleId)
      .filter((vehicleId): vehicleId is string => !!vehicleId)
      .filter((vehicleId, index, self) => self.indexOf(vehicleId) === index); // 重複排除

    const currentTotalWeight = (id: string): number => {
      const assignedOrderDivideds = assignedRotations
        .flatMap(rotation => rotation.orderDivideds)
        .filter(orderDivided => orderDivided.vehicleId === id);

      const assignedOrderDetails = assignedOrderDivideds
        .flatMap(orderDivided => orderDivided.orderDetails)
        .filter(detail => detail?.pointType === 2);

      // 各値を100倍して整数として計算し、最後に100で割る
      const totalLoadWeight = assignedOrderDetails
        .reduce((acc, detail) => {
          const weight = detail?.loadWeight || 0;
          return acc + Math.round(weight * 100);
        }, 0) / 100;

      return Number(totalLoadWeight.toFixed(1));
    };

    const currentTotalPallets = (id: string): number => {
      const assignedOrderDivideds = assignedRotations
        .flatMap(rotation => rotation.orderDivideds)
        .filter(orderDivided => orderDivided.vehicleId === id);

      const assignedOrderDetails = assignedOrderDivideds
        .flatMap(orderDivided => orderDivided.orderDetails)
        .filter(detail => detail?.pointType === 2);

      const totalPallets = assignedOrderDetails
        .reduce((acc, detail) => acc + (Number(detail?.loadPallets) || 0), 0);

      return totalPallets;
    };

    const calculateRatesAndFare = (vehicle?: DeliveryPartnerVehicle) => {
      const loadingRate = vehicle && vehicle.maxWeight ? Math.floor(currentTotalWeight(vehicle.id || "") / (vehicle.maxWeight * 100) * 100) : 0;
      const palletRate = vehicle && vehicle.maxPallets ? Math.floor(currentTotalPallets(vehicle.id || "") / vehicle.maxPallets * 100) : 0;
      const farePerKg = vehicle ? Math.floor((vehicle?.fare || 0) / (currentTotalWeight(vehicle?.id || "") || 1)) : 0;
      return { loadingRate, palletRate, farePerKg };
    };

    const title = (isLast?: boolean, index?: number, vehicle?: DeliveryPartnerVehicle) => {
      // 稼働率とパレット率を事前計算
      const { loadingRate, palletRate, farePerKg } = calculateRatesAndFare(vehicle);

      return (
        <div>
          <div
            style={{
              fontSize: vehicle ? 12 : 13,
              fontWeight: "bold",
              color: "#6B7280",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F0F2F6",
            }}
          >
            {index &&
              <span
                style={{
                  backgroundColor: "white",
                  borderRight: "1px solid #E6EAF2",
                  padding: "4px 8px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {index}
              </span>
            }
            <span
              style={{
                backgroundColor: "white",
                flex: 1,
                padding: "4px 8px",
                display: "flex",
                alignItems: "center"
              }}
            >
              {vehicle ?
                `${VehicleType[vehicle?.vehicleType as VehicleType]}・${vehicle?.weight}t`
                :
                partnerCompany?.name
              }
            </span>
            {isLast &&
              <Popover
                trigger={"hover"}
                mouseLeaveDelay={0.1}
                content={
                  <Select
                    style={{ width: "100%" }}
                    options={(() => {
                      const vehicleTypeMap = new Map<VehicleType, { options: { label: string; vehicleIds: string[]; value: string; disabled: boolean }[] }>();

                      partnerCompany.deliveryPartnerVehicles?.forEach((vehicle: DeliveryPartnerVehicle) => {
                        if (!vehicle.id) {
                          return;
                        }
                        const label = `${VehicleType[vehicle.vehicleType]}(${vehicle.weight}t)`;
                        const vehicleType = vehicle.vehicleType;

                        if (!vehicleTypeMap.has(vehicleType)) {
                          vehicleTypeMap.set(vehicleType, { options: [] });
                        }

                        const vehicleGroup = partnerCompany.deliveryPartnerVehicles?.filter(v => v.vehicleType === vehicle.vehicleType && v.weight === vehicle.weight);
                        const unassignedVehiclesInGroup = vehicleGroup?.filter(v => v.id && !assignedVehicleIds?.includes(v.id));

                        const option = {
                          value: unassignedVehiclesInGroup && unassignedVehiclesInGroup.length > 0 ? unassignedVehiclesInGroup[0].id || "" : vehicle.id,
                          label: label,
                          disabled: unassignedVehiclesInGroup?.length === 0,
                          vehicleIds: [vehicle.id],
                        };

                        const existingType = vehicleTypeMap.get(vehicleType);
                        if (existingType) {
                          // 同じvehicleTypeのoptionが既に存在するか確認
                          const existingOption = existingType.options.find(o => o.label === label);
                          if (existingOption) {
                            existingOption.vehicleIds = [...existingOption.vehicleIds, vehicle.id];
                          } else {
                            existingType.options.push(option);
                          }
                        }
                      });

                      // Mapから配列に変換
                      const sortedOptions: { label: string; vehicleIds: string[]; value: string; disabled: boolean }[] = [];
                      vehicleTypeMap.forEach((value) => {
                        sortedOptions.push(...value.options);
                      });
                      return sortedOptions;

                    })()}
                    onSelect={(selectedVehicleId) => {
                      partnerCompany.id && onSelectPatnerVehicle(selectedVehicleId, partnerCompany.id,);
                    }}
                  />
                }
                title={"車両追加"}
                placement="bottom"
              >
                <span style={{
                  width: 24,
                  height: 29,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}>
                  <PlusOutlined
                    size={16}
                    color="#7C8393"
                  />
                </span>
              </Popover>
            }
          </div>
          {vehicle ?
            <Flex vertical>
              <Flex vertical gap={4} style={{ padding: 8, backgroundColor: "#F7F8FA" }}>
                <Row align="middle">
                  <Col span={10} style={{ fontSize: 11, color: "#5C6171" }}>
                    積載率
                  </Col>
                  <Col>
                    <span style={{ borderLeft: "1px solid #CDD2DC", margin: "0 8px" }} />
                  </Col>
                  <Col span={3} style={{ color: loadingRate > 100 ? "#ff4d4f" : "initial", display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: loadingRate > 100 ? 11 : 13, fontWeight: "bold" }}>{loadingRate}</div>
                    <div style={{ fontSize: 11 }}>%</div>
                  </Col>
                  <Col span={8}>
                    <Progress style={{ margin: "0 0 0 8px" }} showInfo={false} percent={loadingRate} strokeColor={loadingRate > 100 ? "#ff4d4f" : "#A9A8FF"} />
                  </Col>
                </Row>
                <Row align="middle">
                  <Col span={10} style={{ fontSize: 11, color: "#5C6171" }}>
                    パレット使用率
                  </Col>
                  <Col>
                    <span style={{ borderLeft: "1px solid #CDD2DC", margin: "0 8px" }} />
                  </Col>
                  <Col span={3} style={{ color: palletRate > 100 ? "#ff4d4f" : "initial", display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: palletRate > 100 ? 11 : 13, fontWeight: "bold" }}>{palletRate}</div>
                    <div style={{ fontSize: 11 }}>%</div>
                  </Col>
                  <Col span={8}>
                    <Progress
                      style={{ margin: "0 0 0 8px" }}
                      showInfo={false}
                      percent={palletRate}
                      strokeColor={palletRate > 100 ? "#ff4d4f" : "#A9A8FF"}
                    />
                  </Col>
                </Row>
                <Row align="middle">
                  <Col span={10} style={{ fontSize: 11, color: "#5C6171" }}>
                    運賃
                  </Col>
                  <Col>
                    <span style={{ borderLeft: "1px solid #CDD2DC", margin: "0 8px" }} />
                  </Col>
                  <Col span={11}>
                    {/* <Flex vertical>
                      <div>
                        <span style={{ fontSize: 12, fontWeight: "bold" }}>{vehicle?.fare?.toLocaleString()}</span>
                        <span style={{ fontSize: 11 }}>円</span>
                      </div>
                      <div>
                        <span style={{ fontSize: 11 }}>{`${farePerKg}円/kg` || "--"}</span>
                      </div>
                    </Flex> */}
                    <span style={{ fontSize: vehicle?.fare?.toLocaleString().length || 0 > 4 ? 10 : 11, fontWeight: "bold" }}>{vehicle?.fare?.toLocaleString()}</span>
                    <span style={{ fontSize: vehicle?.fare?.toLocaleString().length || 0 > 4 ? 10 : 11 }}>円({`${farePerKg}円/kg` || "--"})</span>
                  </Col>
                </Row>
              </Flex>
              <Divider style={{ margin: 0 }} />
            </Flex> :
            <Divider style={{ margin: 0 }} />
          }
          {/* {!vehicle &&
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Select
              defaultValue={vehicle?.id}
              style={{ width: "100%" }}
              options={partnerCompany.deliveryPartnerVehicles?.map((vehicle: DeliveryPartnerVehicle) => {
                return {
                  value: vehicle.id,
                  label: `${VehicleType[vehicle.vehicleType]}(${vehicle.weight}t)`,
                  disabled: assignedVehicleIds?.includes(vehicle.id)
                };
              })}
              onSelect={(selectedVehicleId) => {
                partnerCompany.id && onSelectPatnerVehicle(selectedVehicleId, partnerCompany.id, assignedRotations.filter(rotation => rotation.vehicleId === vehicle?.id));
              }}
            />
            </div>
          } */}
          {/* <Checkbox
            onChange={(e) =>
              onCheckAllRotations(partnerCompany.id! + vehicle?.id, e.target.checked)
            }
            style={{
              color: "black",
            }}
         >
            すべての運行指示を選択
          </Checkbox> */
          }
        </div >
      );
    };

    const partnerVehicles = assignedVehicleIds?.map((vehicleId, index) => {
      const vehicle = partnerCompany.deliveryPartnerVehicles?.find(vehicle => vehicle.id === vehicleId);

      return {
        id: partnerCompany?.id + vehicleId,
        name: partnerCompany.name,
        container: true,
        driver: true,
        parent: partnerCompany?.id,
        maxWeight: vehicle?.maxWeight,
        maxPallets: vehicle?.maxPallets,
        fare: vehicle?.fare,
        isChartered: true,
        isDropDisabled: !vehicleId,
        ...calculateRatesAndFare(vehicle),
        title: () =>
          title(
            (assignedVehicleIds.length - 1 === index),
            index + 1,
            vehicle
          ),
      };
    });

    return [
      // 運送会社
      {
        id: partnerCompany?.id || '',
        name: partnerCompany.name,
        container: true,
        driver: true,
        isChartered: true,
        isDropDisabled: false,
        title: () => title(!partnerVehicles.length)
      },
      // 上記の保有する運送会社車両
      ...partnerVehicles,
    ]
  };

  const onSelectPatnerVehicle = async (vehicleId: string, partnerCompanyId: string, rotations?: Rotation[]) => {
    const promises = (rotations?.length ? rotations : [{} as Rotation]).map((rotation) => {
      const rotationObj: Rotation = {
        ...rotation,
        vehicleId,
        partnerCompanyId,
        deliveryDate: dayjs(props.selectedDate).format("YYYY/MM/DD"),
        isAssigned: false
      };

      return postRotationApi.execute(
        {
          ...rotationObj,
          orderDivideds: rotationObj.orderDivideds || [],
        },
        true
      );
    });

    await Promise.all(promises);
  }

  const getData = () => {
    return {
      drivers: [
        // 自社
        ...props.vehicles
          .sort((a, b) => {
            return (a.displayOrder || 0) - (b.displayOrder || 0); // displayOrderで昇順にソート
          })
          .map((vehicle, index) => gendriver(vehicle)),
        // 傭車
        ...props.deliveryPartnerCompanies
          .sort((a, b) =>
            (reorderDeliveryPartnerCompanySettings[a.id!]?.order ?? 0) -
            (reorderDeliveryPartnerCompanySettings[b.id!]?.order ?? 0)
          )
          .flatMap(partnerCompany => genPartnerCompany(partnerCompany)),
        // 未割当
        gendriver(),
      ],
    };
  };

  const getDriver = (id: string) => {
    const vehicleUser = props.vehicles.find((vehicle) => vehicle.id === id)?.user;
    if (vehicleUser) {
      return vehicleUser;
    }

    const partnerCompany = props.deliveryPartnerCompanies.find(partnerCompany => id.includes(partnerCompany.id || ''));
    const partnerCompanyName = partnerCompany?.name || "";
    const vehicle = partnerCompany?.deliveryPartnerVehicles?.find(vehicle => id.includes(vehicle?.id || ""));
    const vehicleType = VehicleType[vehicle?.vehicleType as VehicleType];
    const weight = vehicle?.weight || 0;

    return { name: `${partnerCompanyName}(${vehicleType}${weight}t)` };
  };


  const driverOptions = props.vehicles.map((vehicle: Vehicle) => {
    return {
      value: vehicle?.id,
      label: vehicle.user?.name,
    };
  });
  const partnerVehicleOptions = props.deliveryPartnerCompanies
    .flatMap(
      (partnerCompay) =>
        partnerCompay.deliveryPartnerVehicles?.map((vehicle) => ({
          value: `${vehicle?.id},${partnerCompay?.id}`,
          label: `${partnerCompay?.name}(${VehicleType[vehicle?.vehicleType as VehicleType]
            }${vehicle?.weight}t)`,
        }))
    )
    .filter((option): option is { value: string; label: string } => !!option);

  const unAssignedOrderIds = props.form.object?.map((order: Order) => order.id);
  const [data, setData] = useState<DeliveriesKanbanState>({
    items: [],
  });

  const onAddRotation = (parent?: string) => {
    setData((prev) => ({
      items: [
        ...prev.items,
        {
          id: (prev.items.length + 1).toString(),
          container: true,
          driver: false,
          parent,
          rotationTurn: getItems(parent).filter((item) => item.container)
            .length,
        },
      ],
    }));
  };

  const isContainer = (id: number | string): boolean => {
    const item = data.items.find((item) => item.id === id);
    return !item ? false : item.container!;
  };

  const isdriver = (id: number | string): boolean => {
    const item = data.items.find((item) => item.id === id);
    return !item ? false : item.driver!;
  };

  const getItems = (parent?: string): ItemData[] => {
    return data.items.filter((item) => {
      if (!parent) {
        return !item.parent;
      }
      return item.parent === parent;
    });
  };

  const getItemIds = (parent?: string): string[] => {
    return getItems(parent).map((item) => item.id);
  };

  const findParent = (id: number | string): string | null => {
    const item = data.items.find((item) => item.id === id);
    return !item ? null : item.parent!;
  };

  const getDragOverlay = () => {
    if (!activeId) {
      return null;
    }
    const item = data.items.find((i) => i.id === activeId);
    if (!item?.id) {
      return null;
    }
    return item?.driver ?
      <Container driver={item?.driver}>
        {
          getItems(activeId).map((item) => (
            <Item
              key={item.id}
              item={item}
              getItems={(id: string) => []}
              onSave={updateOrder}
              onDeleteDivided={(id: string) => { }}
              onDeleteOrder={(id: string) => { }}
              driverOptions={driverOptions ?? []}
            />
          ))
        }
      </Container> :
      <Item
        key={item.id}
        item={item}
        getItems={(id: string) => []}
        onSave={updateOrder}
        onDeleteDivided={(id: string) => { }}
        onDeleteOrder={(id: string) => { }}
        driverOptions={driverOptions ?? []}
      />
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const { id } = active;
    setActiveId(id.toString());
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const { id } = active;
    let overId = over ? over.id : null;
    if (over) {
      overId = over.id;
    }
    const overParent = findParent(overId!);
    const overIsContainer = isContainer(overId!);
    const activeIsContainer = isContainer(activeId!);
    if (overIsContainer) {
      const overIsdriver = isdriver(overId!);
      const activeIsdriver = isdriver(activeId!);
      if (overIsdriver) {
        if (activeIsdriver) {
          return;
        }
        if (!activeIsContainer) {
          return;
        }
      } else if (activeIsContainer || activeIsdriver) {
        return;
      }
    } else {
      return;
    }
    setData((prev) => {
      const activeIndex = data.items.findIndex((item) => item.id === id);
      const overIndex = data.items.findIndex((item) => item.id === overId);
      let newIndex = overIndex;
      const isBelowLastItem = over && overIndex === prev.items.length - 1;
      const modifier = isBelowLastItem ? 1 : 0;
      newIndex = overIndex >= 0 ? overIndex + modifier : prev.items.length + 1;
      let nextParent: any;
      if (overId) {
        nextParent = overIsContainer ? overId : overParent;
      }
      prev.items[activeIndex].parent = nextParent;

      const nextItems = arrayMove(prev.items, activeIndex, newIndex);
      return { items: nextItems };
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { id } = active;
    let overId = over?.id || null;
    const overIsContainer = isContainer(overId!);
    const activeIsContainer = isContainer(activeId!);
    const overIsdriver = isdriver(overId!);
    const activeIsdriver = isdriver(activeId!);
    if (!overIsContainer && activeIsContainer) {
      return;
    } else if (activeIsdriver && overIsContainer && !overIsdriver) {
      // ドライバー移動中に回転にドロップしたら、その回転の所属するドライバーにドロップする。
      overId = findParent(overId || "");
    }
    const activeIndex = data.items.findIndex((item) => item.id === id);
    const activeItem = data.items[activeIndex];
    if (activeItem.id === overId && !activeItem.container) {
      // カードドロップ時の処理を移動中のカードのparentに寄せる
      overId = activeItem.parent || "";
    }

    const overIndex = data.items.findIndex((item) => item.id === overId);
    const newIndex = overIndex >= 1 ? overIndex : 1;

    if (
      activeIndex !== overIndex ||
      (activeItem.container && !activeItem.driver)
    ) {
      // 配車、回転、ドライバーがドロップされる時
      setData((prev) => {
        const newItems = [...prev.items];
        if (
          unAssignedOrderIds.includes(id.toString()) &&
          activeItem.parent !== "undefined"
        ) {
          // 未割当にある配送が回転内にドロップされる時、未割当内に配送を複製
          const itemCopy = JSON.parse(JSON.stringify(activeItem));
          itemCopy.parent = "undefined";
          newItems.splice(newIndex, 0, itemCopy);
          // ドラッグ中の配車のidを変更
          activeItem.id = `${id}・${newItems.length.toString()}`;
        }
        // orderDividedをorderに追加
        newItems.forEach((item) => {
          if (data.items[activeIndex].order?.id === item.order?.id) {
            if (item.order && activeItem.parent !== "undefined") {
              if (!item.order.orderDivideds) {
                item.order.orderDivideds = []; // orderDividedsが存しない場合に空の配列を初期化
              }
              const driver = getDriver(
                data.items.find((item) => item.id === activeItem.parent)!
                  .parent!
              );
              if (newItems.length > prev.items.length) {
                // 分割配車カードを複製した時
                // 項目を追加
                const copiedOrderDivideds = JSON.parse(
                  JSON.stringify(item.order.orderDivideds)
                );
                const newOrderDivided: OrderDivided = {
                  id: activeItem.id,
                  orderId: item.order.id,
                  consignorId: item.order.consignorId,
                  driverName: driver ? driver.name : "未割当",
                  turnInRotation: getItemIds(item.parent).length - 1,
                  loadWeight: 0.0,
                  charterType: item.order.charterType,
                  orderDetails: item.order.orderDetails?.map((detail) => {
                    // detailの残りの重量を計算
                    const calculatedLoadWeight = Number(detail.loadWeight || 0) - (item.order?.orderDivideds?.flatMap(orderDivided => orderDivided.orderDetails).filter(orderDetail => orderDetail?.deliveryCompanyId === detail.deliveryCompanyId && orderDetail?.pointType === detail.pointType && detail.no === detail.no)?.reduce((sum, orderDetail) => sum + (Number(orderDetail?.loadWeight) || 0), 0) || 0);
                    const calculatedQuantity = Number(detail.quantity || 0) - (item.order?.orderDivideds?.flatMap(orderDivided => orderDivided.orderDetails).filter(orderDetail => orderDetail?.deliveryCompanyId === detail.deliveryCompanyId && orderDetail?.pointType === detail.pointType && detail.no === detail.no)?.reduce((sum, orderDetail) => sum + (Number(orderDetail?.quantity) || 0), 0) || 0);
                    return ({
                      ...detail,
                      id: "0",
                      // 配車カードをドロップする時、残りの全重量を分割する。
                      loadWeight: calculatedLoadWeight,
                      quantity: calculatedQuantity,
                    });
                  }),
                };
                copiedOrderDivideds.push(newOrderDivided);
                item.order.orderDivideds = copiedOrderDivideds;
                if (item.id === newOrderDivided.id) {
                  item.orderDivided = newOrderDivided;
                }
              } else {
                // 分割配車カードを移動した時にドライバー名を割り当てる。
                const target = item.order.orderDivideds.find(
                  (orderDivided) => orderDivided.id === activeItem.id
                );
                target!.driverName = driver ? driver.name : "未割当";
                if (item.orderDivided?.id === activeItem.id)
                  item.orderDivided!.driverName = driver
                    ? driver.name
                    : "未割当";
              }
            } else if (
              item.container &&
              !item.driver &&
              activeItem.id === item.id
            ) {
              // 回転を移動した時にドライバー名を割り当てる。
              const cardsMovdes = getItems(activeItem.id);
              cardsMovdes.forEach((cardMoved) => {
                const driver = getDriver(activeItem.parent!);
                cardMoved.orderDivided!.driverName = driver
                  ? driver.name
                  : "未割当";
                const targetCards = data.items.filter(
                  (item) => item.order?.id === cardMoved.order?.id
                )!;
                targetCards.forEach((card) => {
                  const target = card.order?.orderDivideds?.find(
                    (orderDivided) =>
                      orderDivided.id === cardMoved.orderDivided?.id
                  );
                  target!.driverName = driver ? driver.name : "未割当";
                });
              });
            }
          }
        });
        if (activeItem.driver) {
          // ドライバー移動の時、移動を記録するため更新
          const displayOrder = newIndex;
          vehicleForm.set({
            ...(props.vehicles.find((v) => v.id === activeItem.id) || {}),
            displayOrder,
          });
          setShouldExecuteVehicleApi(true);
        }
        return { items: arrayMove(newItems, activeIndex, newIndex) };
      });
      const newItems = arrayMove(data.items.slice(), activeIndex, newIndex);
      handleUpdateRotation(activeItem, newItems);
    } else if (unAssignedOrderIds.includes(id.toString())) {
      // 未割当にある配送が不慮のドラッグ&ドロップをされたとき、元の位置に戻す
      activeItem.parent = "undefined";
    }
    setActiveId(null);
  };

  const handleUpdateRotation = async (
    activeItem: ItemData,
    newItems: ItemData[]
  ) => {
    if (
      activeItem.driver ||
      (!activeItem.driver &&
        !activeItem.container &&
        activeItem.parent === "undefined")
    ) {
      // ドライバーの移動もしくは未割当への配車カードの移動なら以降実行しない
      return;
    }
    // apiを使ったrotationの更新を行う。
    // 分割配車カード
    let rotation: ItemData;
    let order: Order = {};
    if (activeItem.container) {
      // 回転をドロップした時
      if (getItemIds(activeItem.id).length === 0) {
        props.onListRotations();
        return;
      }
      // 回転を移動した時にrotationTurnを更新する
      const childrenRotations = newItems
        .filter((item) => item.parent === activeItem.parent)
        .filter((rotation) => getItems(rotation.id).length > 0);
      const index = childrenRotations.findIndex(
        (item) => item.id === activeItem.id
      );
      activeItem.rotationTurn = index;
      rotation = activeItem;
    } else {
      // 配車カードをドロップした時、
      rotation = data.items.find((item) => item.id === activeItem.parent)!;
      order = activeItem.order!;
    }
    if (
      rotation.isAssigned &&
      (props.rotations.find((rot) => rot.id === rotation.id)?.vehicleId !==
        rotation.parent ||
        !activeItem.container)
    ) {
      // 運行指示済みの回転が異なるドライバーにドロップされたら
      submitInstructionsCancel([rotation.id]);
    }

    const vehicleId = [...props.vehicles, ...props.deliveryPartnerCompanies.flatMap(company => company.deliveryPartnerVehicles)].find(vehicle => vehicle?.id && rotation.parent?.includes(vehicle.id))?.id || null;
    const partnerCompanyId = props.deliveryPartnerCompanies.find(partner => partner.id && rotation.parent?.includes(partner.id))?.id || null;
    const orderDivideds = newItems
      .filter((item) => item.parent === rotation.id)
      .map((item) => ({
        ...item.orderDivided,
        vehicleId,
        partnerCompanyId,
        charterType: item.order?.charterType,
      }));

    const maxWeight = data.items.find(
      (item) => item.id === findParent(rotation.id)
    )?.maxWeight;

    const rotationObj: Rotation = {
      id: rotation.id,
      vehicleId,
      partnerCompanyId,
      userId: getDriver(vehicleId || "")?.id,
      rotationTurn: rotation.rotationTurn,
      maxWeight: maxWeight || 0,
      orderDivideds: orderDivideds,
      deliveryDate: dayjs(props.selectedDate).format("YYYY/MM/DD"),
    };
    const res = await postRotationApi.execute(
      {
        ...rotationObj,
        orderDivideds: rotationObj.orderDivideds
          ? rotationObj.orderDivideds
          : [],
      },
      true
    );

    // 以降配車カードドロップ時の処理
    if (!order.orderDivideds?.length) {
      return;
    }
    // 分割配車カードをドロップした時、新規の分割配車カードを追加
    const newOrderDivided = res.data.orderDivideds.find(
      (d: OrderDivided) => d.orderId === order.id
    );
    const existingOrderDivideds = order.orderDivideds?.filter(d => !!d.rotationId && d.id !== newOrderDivided?.id) || [];
    order.orderDivideds = [
      ...existingOrderDivideds,
      newOrderDivided,
    ];
    // ドロップ後の総重量で受注を更新
    await updateOrder(order);

    // 配車カードがドロップされて、deliveryDateが入っていない時
    if (
      !isEmpty(order) &&
      order.orderDetails
        ?.filter((detail) => detail.pointType === 2)
        .every((detail) => !detail.deliveryDate)) {
      order.orderDetails.forEach(
        (detail) => (detail.deliveryDate = rotationObj.deliveryDate)
      );
      editOrderApi.execute(order);
    }
  };

  const handleEditOrder = async (order: OrderForm) => {
    const original = data.items.find((item) => item.id === order.id)?.order;
    const newRotations: Rotation[] = [];

    if (original) {
      // 引数orderにしか存在せず、新規の回転に属するorderDividedsを抽出
      const newRotationDivideds = order.orderDivideds?.filter(
        (orderDivided) =>
          !original.orderDivideds?.some(
            (originalDivided) => originalDivided.id === orderDivided.id
          ) && !orderDivided.rotationId
      );
      // 更新対象のorderDividedsかnewRotationDividedsを除外
      order.orderDivideds = order.orderDivideds?.filter(
        (divided) => !newRotationDivideds?.includes(divided)
      );

      if (newRotationDivideds?.length) {
        // ドライバーごとにorderDividedsをまとめる。
        const groupedByVehicleId = newRotationDivideds?.reduce<
          Record<string, OrderDivided[]>
        >((acc, curr) => {
          const vehicleId = curr.vehicleId || "";
          const partnerCompanyId = curr.partnerCompanyId;
          const groupId = `${vehicleId},${partnerCompanyId || ''}`;
          if (!acc[groupId]) {
            acc[groupId] = [];
          }
          acc[groupId].push(curr);
          return acc;
        }, {});
        Object.entries(groupedByVehicleId).forEach(
          ([groupId, orderDivideds]) => {
            newRotations.push({
              id: "",
              vehicleId: groupId.split(",")[0],
              partnerCompanyId: groupId.split(",")[1],
              userId: getDriver(groupId.split(",")[0])?.id,
              rotationTurn: orderDivideds[0].rotationTurn,
              maxWeight:
                props.vehicles.find((v) => v.id === groupId.split(",")[0])?.maxWeight ||
                props.deliveryPartnerCompanies.flatMap(pc => pc.deliveryPartnerVehicles).find((v) => v?.id === groupId.split(",")[0])?.maxWeight,
              orderDivideds: orderDivideds,
              deliveryDate: dayjs(props.selectedDate).format("YYYY/MM/DD"),
            });
          }
        );
      }
    }
    const divideds = [];
    // orderの更新とrotationの新規作成を行う
    for (let rotation of newRotations) {
      const res = await postRotationApi.execute(rotation, false);
      rotation = res.data;
      if (rotation.orderDivideds) {
        divideds.push(rotation.orderDivideds);
      }
    }
    // 除外したorderDividedsを再挿入
    order.orderDivideds = [
      ...divideds.flatMap((divided) => divided),
      ...(order.orderDivideds || []),
    ];
    // if (order.orderDivideds.length === 0) {
    //   // 編集モーダルで分割配車を0にしたときのケースをカバー
    //   order.orderDivideds = [
    //     {
    //       id: "0",
    //       charterType: 0,
    //       turnInRotation: 0,
    //       orderDetails: [
    //         {
    //           id: "0",
    //           deliveryCompanyId: "0",
    //           pointType: 1,
    //           no: 0,
    //           loadWeight: 0,
    //         },
    //       ],
    //     },
    //   ];
    // }
    updateOrder(order);
  };

  const updateOrder = async (order: OrderForm) => {
    // orderDetailから一番若い積み日を取得
    const eligibleDates = order
      .orderDetails!.filter(
        (detail) => detail.pointType === 2 && detail.deliveryDate !== null
      )
      .map((detail) => dayjs(detail.deliveryDate!).format("YYYY/MM/DD"));
    const earliestDate = eligibleDates.length
      ? eligibleDates.reduce(
        (minDate, currentDate) =>
          dayjs(currentDate).isBefore(minDate) ? currentDate : minDate,
        eligibleDates[0]
      )
      : null;

    const rotationIds = order.orderDivideds?.map(
      (divided) => divided.rotationId
    );
    // 編集対象の受注を含む回転のうち、日付が最新のものと一致しない回転をフィルタして運行指示を外す
    const rotations = props.rotations.filter(
      (rotation) =>
        rotationIds?.includes(rotation.id) &&
        dayjs(rotation.deliveryDate).format("YYYY/MM/DD") !== earliestDate
    );

    // 受注と日付の一致しない回転がある時、回転からその受注を外して別の回転を作成
    if (rotations.length) {
      order.orderDivideds?.forEach((divided) => {
        // 元の divided.orderDetails を新しい配列としてコピー
        const updatedOrderDetails = [...divided.orderDetails!];

        // order.orderDetails と比較して上書き処理
        updatedOrderDetails.forEach((detail, index) => {
          const matchingDetail = order.orderDetails!.find(
            (orderDetail) =>
              orderDetail.deliveryCompanyId === detail.deliveryCompanyId
          );

          if (matchingDetail) {
            updatedOrderDetails[index].deliveryDate =
              matchingDetail.deliveryDate;
          }
        });

        const vehicleId = findParent(divided.rotationId!);
        const userId = getDriver(vehicleId || "")?.id;
        postRotationApi.execute({
          userId,
          vehicleId,
          maxWeight:
            data.items.find((item) => item.id === vehicleId)?.maxWeight || 0,
          orderDivideds: [divided],
          deliveryDate: earliestDate,
        });
      });
    }
    // 運行指示を外す
    submitInstructionsCancel(
      rotations
        .filter((rotation) => rotation.isAssigned)
        .map((rotation) => rotation.id!)
    );
    // 受注を編集
    await editOrderApi.execute(order!);
  };

  const updateDriverOption = async (rotationId: string, vehicleId: string) => {
    let newItems: ItemData[] = [];
    setData((prev) => {
      newItems = [...prev.items];
      const targetRotation = newItems.find((item) => item.id === rotationId);
      targetRotation!.parent = vehicleId;
      return { items: newItems };
    });
    const rotation = {
      id: rotationId,
      vehicleId,
      userId: getDriver(vehicleId)?.id,
      rotationTurn: Math.max(
        ...newItems
          .filter((item) => item.parent === vehicleId)
          .map((rotation) => rotation.rotationTurn! + 1 || 0)
      ),
    };
    const orderDivideds = data.items
      .filter((item) => item.parent === rotation.id)
      .map((item) => ({
        ...item.orderDivided,
        vehicleId,
      }));

    await postRotationApi.execute(
      { ...rotation, orderDivideds: orderDivideds ? orderDivideds : [] },
      false
    );
    submitInstructionsCancel([rotation.id]);
  };

  const deleteRotationById = async (rotationId: string) => {
    let newItems: ItemData[] = [];
    setData((prev) => {
      newItems = [...prev.items];
      newItems = newItems.filter((item) => item.id !== rotationId);
      return { items: newItems };
    });
    if (props.rotations.map((rot) => rot.id).includes(rotationId)) {
      await deleteRotationApi.execute(rotationId, getItems(rotationId).length === 0);
      const ids = getItems(rotationId).map((item) => item.id);
      getItems(rotationId).forEach(async (item, index, items) => {
        item.order!.orderDivideds = item.order?.orderDivideds?.filter(
          (divided) => !ids.includes(divided.id!)
        );
        if (index === items.length - 1) {
          await updateOrder(item.order!);
        } else {
          updateOrder(item.order!);
        }
      });
    }
  };

  const deleteOrderDividedById = async (orderDividedId: string) => {
    let newItems: ItemData[] = [];
    const deleted = data.items.find((item) => item.id == orderDividedId);
    setData((prev) => {
      newItems = [...prev.items];
      newItems = newItems.filter((item) => item.id !== orderDividedId);
      return { items: newItems };
    });
    // orderDividedを削除
    await deleteOrderDividedApi.execute(orderDividedId, false);
    // order自体も更新
    deleted!.order!.orderDivideds = deleted!.order!.orderDivideds?.filter(
      (divided) => divided.id !== orderDividedId
    );
    await updateOrder(deleted!.order!);
  };

  const onCheck = (rotationId: string, isChecked: boolean) => {
    setData((prev) => {
      const rotation = prev.items.find((item) => item.id === rotationId);
      rotation!.isChecked = isChecked;
      return { items: [...prev.items] };
    });
  };

  const onCheckAllRotations = (driverId: string, isChecked: boolean) => {
    setData((prev) => {
      const driver = prev.items.find((item) => item.id === driverId);
      driver!.isChecked = isChecked;
      const rotations = prev.items.filter((item) => item.parent === driverId);
      rotations.forEach((rotation) => {
        rotation.isChecked = isChecked;
      });
      return { items: [...prev.items] };
    });
  };

  const onCheckAllDrivers = (isChecked: boolean) => {
    setData((prev) => {
      const rotations = prev.items.filter(
        (item) => !item.driver && item.container && getItems(item.id).length > 0
      );
      rotations.forEach((rotation) => {
        rotation.isChecked = isChecked;
      });
      return { items: [...prev.items] };
    });
  };

  // 積み・降しの日付と重量の入力をvalidate
  const validateInstructions = (ids: string[]) => {
    const messages: string[] = [];
    let flg = true;
    ids.forEach((id) => {
      const items = getItems(id);
      items.forEach((item) => {
        item.order?.orderDetails?.forEach((detail) => {
          if (detail.deliveryDate === null) {
            messages.push(
              `受注番号:${item.order?.orderNo}${detail.deliveryCompany?.name}の積み・降し日が設定されていません。`
            );
            flg = false;
          }
          if (detail.loadWeight === null) {
            messages.push(
              `受注番号:${item.order?.orderNo}の${detail.deliveryCompany?.name}の積荷の重量が設定されていません。`
            );
          }
        });
      });
    });
    return {
      flg: flg,
      messages: messages,
    };
  };

  const [isAllDriversChecked, setIsAllDriversChecked] = useState(false);
  useEffectSkipFirst(() => {
    setIsAllDriversChecked(false);
  }, [props.selectedDate])
  const submitInstructions = () => {
    const ids = data.items
      .filter(
        (item) =>
          !item.driver &&
          item.container &&
          item.isChecked &&
          !item.isAssigned &&
          getItems(item.id).length > 0
      )
      .map((item) => item.id);
    const validate = validateInstructions(ids);
    validate.messages.forEach((msg) => {
      message.info(msg);
    });
    if (validate.flg && ids.length > 0) {
      postRotationInstructionsApi.execute({ ids: ids, isAssigned: true });
    }
    setIsAllDriversChecked(false);
  };

  const submitInstructionsCancel = (ids: string[]) => {
    if (ids.length > 0) {
      postRotationInstructionsApi.execute({ ids: ids, isAssigned: false });
    }
  };

  const storageKey = "order-partner-companies";
  const [reorderDeliveryPartnerCompanySettings, setReorderDeliveryPartnerCompanySettings] = useState(
    LocalStorageManager.getTableSettings(storageKey)
  );
  const [open, setOpen] = useState(false);

  // reorderDeliveryPartnerCompanyColumnsをdeliveryPartnerCompaniesから生成
  const reorderDeliveryPartnerCompanyColumns = useMemo(() => {
    const columns: ColumnType<DeliveryPartnerCompany>[] = props.deliveryPartnerCompanies.map((company) => ({
      title: company.name,
      dataIndex: "name",
      key: company.id,
    }));
    return columns;
  }, [props.deliveryPartnerCompanies]);

  useEffect(() => {
    if (props.form.isEmpty() || props.deliveryPartnerCompanies.length === 0) {
      return;
    }
    setData(prevData => {
      // deliveryPartnerCompaniesに関連するドライバーアイテムとその子アイテムを抽出
      const partnerIds = props.deliveryPartnerCompanies.map(company => company.id);
      const partnerItems = prevData.items.filter(item => partnerIds.some(id => id && item.id.includes(id)));
      const otherItems = prevData.items.filter(item => !partnerIds.some(id => id && item.id.includes(id)));

      // パートナー企業のドライバーアイテムをソート
      const sortedPartnerItems = partnerItems
        .sort((a, b) => {
          const aPartnerId = partnerIds.find(id => id && a.id!.includes(id)) || '';
          const bPartnerId = partnerIds.find(id => id && b.id!.includes(id)) || '';
          return (reorderDeliveryPartnerCompanySettings[aPartnerId]?.order ?? 0) -
            (reorderDeliveryPartnerCompanySettings[bPartnerId]?.order ?? 0);
        });

      return {
        items: [
          ...otherItems,
          ...sortedPartnerItems
        ]
      };
    });
  }, [reorderDeliveryPartnerCompanySettings]);


  const [filter, setFilter] = useState("");
  const filteredDrivers = useMemo(() => {
    if (!filter) {
      return getItems();
    }
    return getItems().filter(item =>
      item.id === "undefined" || // 未割当レーン
      item.driver &&
      (
        item.isChartered && item.name?.includes(filter) || // 運送会社名の検索
        (getItems(item.id)?.some((rotation) => {
          const orderDivideds = getItems(rotation.id);
          return orderDivideds?.some((divided) => {
            const order = divided.order;
            return order?.orderDetails?.some(
              (detail) =>
                detail.productName?.includes(filter) || // 品名の検索
                detail.deliveryCompany?.name?.includes(filter) // 行先名の検索
            );
          })
        }))
      )
    );
  }, [JSON.stringify(data.items), filter])

  return (
    <div
      style={{
        height: "100%",
        padding: "20px 15px",
        borderRadius: "16px",
      }}
    >
      <Space
        style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}
      >
        <CustomSelectBlockField
          style={{ backgroundColor: "#E6EAF2" }}
          selectItems={
            Object.entries(KanbanMode)
              .map(([label, value]) => (
                { label, value }
              ))
          }
          value={props.mode}
          onChange={(mode) => { props.setMode(mode) }}
        />
        <Space>
          <Button
            onClick={() => setOpen(true)}
            style={{ fontWeight: "bolder" }}
          >
            傭車順を変更
          </Button>
          <Input
            style={{ fontSize: 12 }}
            placeholder="行先/運送会社/品名で検索"
            onChange={(e) => setFilter(e.target.value)}
          />
          <Checkbox
            onChange={(e) => onCheckAllDrivers(e.target.checked)}
            checked={isAllDriversChecked}
            onClick={() => setIsAllDriversChecked(!isAllDriversChecked)}
            style={{ color: "black", fontWeight: "bold" }}
          >
            一括チェック
          </Checkbox>
          <Button onClick={submitInstructions} style={{ fontWeight: "bolder" }}>
            運行指示を送信
          </Button>
        </Space>
      </Space>
      <div style={{ width: "calc(100vw - 294px)" }}>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            id="root"
            items={getItemIds()}
            strategy={verticalListSortingStrategy}
          >
            <div style={wrapperStyle}>
              {filteredDrivers
                .filter(item => (
                  item.isUnassigned ||
                  props.mode === KanbanMode.すべて ||
                  props.mode === KanbanMode.自社 && !item.isChartered ||
                  props.mode === KanbanMode.傭車 && item.isChartered
                ))
                .map((item, index) => {
                  return (
                    <SortableContainer
                      key={item.id}
                      id={item.id}
                      getItems={getItems}
                      driver={item.driver}
                      driverTitle={item.title}
                      driverOptions={driverOptions}
                      partnerVehicleOptions={partnerVehicleOptions}
                      index={index}
                      parent={item.parent}
                      driverUnassigned={item.id === "undefined"}
                      isChartered={item.isChartered}
                      handlePosition="top"
                      maxWeight={item.maxWeight}
                      maxPallets={item.maxPallets}
                      fare={item.fare}
                      onAddRotation={onAddRotation}
                      onSave={handleEditOrder}
                      onSelected={updateDriverOption}
                      onDeleteRotation={deleteRotationById}
                      onDeleteDivided={deleteOrderDividedById}
                      onDeleteOrder={(id: string) => deleteOrderApi.execute(id)}
                      onCheck={onCheck}
                    />
                  );
                })}
            </div>
          </SortableContext>
          <DragOverlay className="hogehoge">{getDragOverlay()}</DragOverlay>
        </DndContext>
      </div>
      <ReorderModal
        open={open}
        setOpen={setOpen}
        settings={reorderDeliveryPartnerCompanySettings}
        setSettings={setReorderDeliveryPartnerCompanySettings}
        tableKey={storageKey}
        columns={reorderDeliveryPartnerCompanyColumns}
        isPersistent
        isDisplaySettingDisabled
      />
    </div>
  );
};
