import { BaseEntity, BaseSearchForm, ID } from ".";
import { Consignor } from "./consignor";
import { DeliveryCompany } from "./delivery_company";
import { Point, PointType } from "./point";
import { Template } from "./template";
import { User } from "./user";
import 入口 from "components/../../public/ 入口.png";
import その他 from "components/../../public/その他.png";
import ヤード from "components/../../public/ヤード.png";
import 回収場所 from "components/../../public/回収場所.png";
import 危険場所 from "components/../../public/危険場所.png";
import 鍵 from "components/../../public/鍵.png";
import 守衛 from "components/../../public/守衛.png";
import 受付 from "components/../../public/受付.png";
import 出口 from "components/../../public/出口.png";
import 場内待機 from "components/../../public/場内待機.png";
import 電話 from "components/../../public/電話.png";
import 上 from "components/../../public/上.svg";
import 下 from "components/../../public/下.svg";
import 左 from "components/../../public/左.svg";
import 右 from "components/../../public/右.svg";
import { ReactNode } from "react";
import { Tag } from "./tag";
import { BooleanValidation, StringValidation } from "specifics/input";
import { Form } from "utils/hooks";
import { DeliveryPartnerCompany } from "./delivery_partner_company";
import { ContractItemMapping, ContractItem, DeliveryTerm, Status } from "./contract";
import { ArrowUpOutlined, ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

type ManualMarkerType = {
  icon: string;
  pointType: PointType;
  name: string;
  componentIcon: ReactNode;
};

export const ManualMarkers: ManualMarkerType[] = [
  {
    icon: 守衛,
    pointType: 2,
    name: "守衛",
    componentIcon: <img src={守衛} style={{ height: 18 }} />,
  },
  {
    icon: 受付,
    pointType: 3,
    name: "受付",
    componentIcon: <img src={受付} style={{ height: 18 }} />,
  },
  {
    icon: ヤード,
    pointType: 4,
    name: "ヤード",
    componentIcon: <img src={ヤード} style={{ height: 18 }} />,
  },
  {
    icon: 場内待機,
    pointType: 5,
    name: "場内待機",
    componentIcon: <img src={場内待機} style={{ height: 18 }} />,
  },
  {
    icon: 入口,
    pointType: 6,
    name: "入口",
    componentIcon: <img src={入口} style={{ height: 18 }} />,
  },
  {
    icon: 出口,
    pointType: 7,
    name: "出口",
    componentIcon: <img src={出口} style={{ height: 18 }} />,
  },
  {
    icon: 危険場所,
    pointType: 8,
    name: "危険場所",
    componentIcon: <img src={危険場所} style={{ height: 18 }} />,
  },
  {
    icon: 回収場所,
    pointType: 9,
    name: "回収場所",
    componentIcon: <img src={回収場所} style={{ height: 18 }} />,
  },
  {
    icon: 鍵,
    pointType: 10,
    name: "鍵",
    componentIcon: <img src={鍵} style={{ width: 22 }} />,
  },
  {
    icon: 電話,
    pointType: 11,
    name: "電話",
    componentIcon: <img src={電話} style={{ height: 18 }} />,
  },
  {
    icon: その他,
    pointType: 12,
    name: "その他",
    componentIcon: <img src={その他} style={{ height: 18 }} />,
  },
  {
    icon: 上,
    pointType: 13,
    name: "上",
    componentIcon: <ArrowUpOutlined style={{ fontSize: 18 }} />,
  },
  {
    icon: 下,
    pointType: 14,
    name: "下",
    componentIcon: <ArrowDownOutlined style={{ fontSize: 18 }} />,
  },
  {
    icon: 左,
    pointType: 15,
    name: "左",
    componentIcon: <ArrowLeftOutlined style={{ fontSize: 18 }} />,
  },
  {
    icon: 右,
    pointType: 16,
    name: "右",
    componentIcon: <ArrowRightOutlined style={{ fontSize: 18 }} />,
  },
]

export enum ManualKey {
  基本情報 = 1,
  手順 = 2,
  構内マップ = 3
}

export enum ManualInfo {
  基本情報 = 0,
  行先での作業 = 1
}

export enum DriverManualInfo {
  基本情報 = 0,
  納入カルテ名 = 1,
  荷渡しと荷役 = 2,
  附帯業務 = 3
}

export const ManualInfoAttrs = {
  "基本情報": ["manualId", "deliveryCompanyId", "consignorId", "title", "isTitleDuplicate"],
  "行先での作業": [
    "deliveryTerm", "deliveryTermOther", "handlingTerm", "existHandlingTask", "handlingTask", "handlingTaskOther", "existNeedTask", "needTask", "needTaskOther"
  ]
}

export const ManualGuidelineAttrs = {
  "行先での作業": [
    "deliveryTerm", "deliveryTermOther", "handlingTerm", "existHandlingTask", "handlingTask", "handlingTaskOther", "existNeedTask", "needTask", "needTaskOther"
  ]
}

export const DriverManualInfoAttrs = {
  "基本情報": ["deliveryCompanyId", "consignorId"],
  "納入カルテ名": ["title", "isTitleDuplicate"],
  "荷渡しと荷役": [
    "deliveryTerm", "deliveryTermOther", "handlingTerm", "existHandlingTask", "handlingTask", "handlingTaskOther"
  ],
  "附帯業務": [
    "existNeedTask", "needTask", "needTaskOther"
  ]
}

export const DriverManualGuidelineAttrs = {
  "荷渡しと荷役": [
    "deliveryTerm", "deliveryTermOther", "handlingTerm", "existHandlingTask", "handlingTask", "handlingTaskOther"
  ],
  "附帯業務": [
    "existNeedTask", "needTask", "needTaskOther"
  ]
}


export type Manual = BaseEntity & ContractItem & {
  manualId?: ID;
  title?: string;
  isTitleDuplicate?: boolean;
  deliveryCompany?: DeliveryCompany;
  deliveryPointCompany?: DeliveryCompany;
  deliveryCompanyId?: ID;
  deliveryPointCompanyId?: ID;
  deliveryPartnerCompany?: DeliveryPartnerCompany;
  deliveryPartnerCompanyId?: ID;
  consignor?: Consignor;
  consignorId?: ID;

  createdBy?: ID;
  updatedBy?: ID;
  userId?: ID;
  user?: User;
  templateId?: string;
  template?: Template;

  manuals?: Manual[];
  manualDetails?: ManualDetail[];
  selectedStepNos?: number[];
  points?: Point[];

  file?: string;

  tags?: Tag[];
  status?: Status;

  requested?: boolean;
};

export type ManualDetail = BaseEntity & {
  manualId?: ID;
  manual?: Manual;
  manualProcesses?: ManualProcess[];
  stepId?: ID;
  stepNo?: number;

  context?: string;
  picture1?: string;
  picture2?: string;
  picture3?: string;
  comment?: string;
};

export type ManualProcess = BaseEntity & {
  manualDetailId?: ID;
  manualDetail?: ManualDetail;
  questionId?: ID;
  questionNo?: number;
  value?: string | null;
  numericValue?: number;
  numericUnit?: string;
  freeText?: string;
  flag?: "u";
};

export type ManualForm = Manual;

export type ManualSearchForm = BaseSearchForm & Manual;

export const validateManual = (
  form: Form<Manual>,
  validationResultForm: Form<Manual>,
  contractRequired?: boolean
) => {
  validationResultForm.update((f) => {
    if (contractRequired) {
      // 実態入力のみのバリデーション
      Object.keys(ContractItemMapping).forEach(key => {
        switch (key) {
          case "deliveryTermOther":
            BooleanValidation(f, form, "deliveryTermOther", form.object.deliveryTerm === DeliveryTerm.その他);
            break;
          case "handlingTaskOther":
            BooleanValidation(f, form, "handlingTaskOther", form.object.handlingTask?.includes("その他"));
            break;
          case "needTaskOther":
            BooleanValidation(f, form, "needTaskOther", form.object.needTaskOther?.includes("その他"));
            break;
          default:
            BooleanValidation(f, form, key as keyof Manual, !key.includes("waitingTime")); // 待ち時間以外は必須入力
        }
      })
    } else {
      StringValidation(f, form, "manualId", !!form.object.deliveryPointCompanyId); // 地点IDがある時、紐づけるカルテIDも必須に
      StringValidation(f, form, "deliveryCompanyId", true);
      StringValidation(f, form, "consignorId", false);
      StringValidation(f, form, "deliveryPartnerCompanyId", false);
      StringValidation(f, form, "templateId", true);
      StringValidation(f, form, "title", true);
      f.isTitleDuplicate = form.object.isTitleDuplicate ? true : undefined;
      // タイトルまでの入力でカルテを作成できるように
      f.manualDetails =
        form.object.manualDetails?.map((manualDetail, stepIndex) => {
          return {
            comment:
              (manualDetail.comment?.length ?? 0) >= 256
                ? "文字列の長さは256文字未満である必要があります 。"
                : undefined,
            manualProcesses: manualDetail.manualProcesses?.map(
              (manualProcess, questionIndex) => {
                return { value: undefined } as ManualProcess;
              }
            ),
          } as ManualDetail;
        }) ?? [];
      f.points = form.object.points?.map(point => {
        return {
          longitude: Number.isNaN(point.longitude) ? "経度は数値である必要があります。" : undefined,
          latitude: Number.isNaN(point.latitude) ? "経度は数値である必要があります。" : undefined,
          pointType: Object.values(PointType).includes(point.pointType ?? "") ? undefined : "pointTypeは規定の値である必要があります。",
          memo: (point.memo?.length ?? 0) >= 256
            ? "文字列の長さは256文字未満である必要があります 。"
            : undefined,
        } as Point
      }) ?? [];
    }
  });
};