import { MetricsForm } from "components/analysis/metrics/metrics_config_form";
import dayjs from "dayjs";
import { AssignedPartnerCompanyDataForm } from "entities/delivery_partner_company";
import Cookies from "js-cookie";
import { TableSettings } from "specifics/table";

enum Key {
  UserToken = "UserToken",
  UserId = "UserId",
  MapActiveVehicleKeys = "MapActiveVehicleKeys",
  MetricsFormHistories = "MetricsFormHistories",
}

export class CookieManager {
  public static saveUserToken(token: string): void {
    Cookies.set(Key.UserToken, token, { expires: 60 });
  }

  public static getUserToken(): string {
    return Cookies.get(Key.UserToken) as string;
  }

  public static removeUserToken(): void {
    Cookies.remove(Key.UserToken);
  }
  public static saveUserId(userId: string): void {
    Cookies.set(Key.UserId, userId, { expires: 60 });
  }

  public static getUserId(): string {
    return Cookies.get(Key.UserId) as string;
  }

  public static removeUserId(): void {
    Cookies.remove(Key.UserId);
  }

  public static hasUserToken(): boolean {
    return !this.getUserToken() ? false : true;
  }

  public static getMapActiveVehicleKeys(date: Date): (string | undefined)[] {
    const dateStr = dayjs(date).format("YYYYMMDD");
    const value = Cookies.get(Key.MapActiveVehicleKeys);
    if (!value) return [];
    else {
      if (Object.keys(JSON.parse(value)).includes(dateStr)) {
        console.log(dateStr, JSON.parse(value));
        return (
          JSON.parse(value) as { [date: string]: (string | undefined)[] }
        )[dateStr];
      } else {
        return [];
      }
    }
  }

  public static setMapActiveVehicleKeys(
    date: Date,
    activeVehicleKeys: (string | undefined)[]
  ): void {
    const value = Cookies.get(Key.MapActiveVehicleKeys);
    const dateStr = dayjs(date).format("YYYYMMDD");
    const parsedJson: Record<string, any> = value ? JSON.parse(value) : {};
    parsedJson[dateStr] = activeVehicleKeys;
    Cookies.set(Key.MapActiveVehicleKeys, JSON.stringify(parsedJson));
  }

  public static getTableSettings = (tableKey: string): TableSettings => {
    const savedSettings = Cookies.get(tableKey);
    return savedSettings ? JSON.parse(savedSettings) : {};
  };

  public static setTableSettings = (
    tableKey: string,
    settings: TableSettings,
    expires?: number
  ) => {
    Cookies.set(tableKey, JSON.stringify(settings), { expires });
  };

  public static removeTableSettings = (tableKey: string) => {
    Cookies.remove(tableKey);
  };

  public static saveMetricsHistories(histories: MetricsForm[]): void {
    Cookies.set(Key.MetricsFormHistories, JSON.stringify(histories));
  }

  public static getMetricsHistories(): MetricsForm[] {
    const strHistories = Cookies.get(Key.MetricsFormHistories) as string;
    try {
      return JSON.parse(strHistories);
    } catch (error) {
      Cookies.remove(Key.MetricsFormHistories);
      return [];
    }
  }
}
