import { Alert, Skeleton, Space, theme } from "antd";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect, useState } from "react";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomSelectBlockField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DeliveriesTable } from "../old/deliveries_old_table";
import { WeekHorizontalView } from "specifics/date_view";
import { DeliveriesKanban } from "../old/deliveries_old_kanban";
import { useFetchAllCharteredCompaniesApi } from "api/chartered_company";
import { useFetchAllVehiclesApi } from "api/vehicle";
import dayjs from "dayjs";
import { Delivery } from "entities/delivery";
import { useEditDeliveryApi, useFetchDeliveriesApi } from "api/delivery";
import { DeliveriesMaps } from "../old/deliveries_old_maps";
import QueryString from "qs";
import { DeliveriesCalendar } from "../deliveries_calendar_modal";

export const DeliveriesOldPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchDeliveriesApi();
  const charteredCompaniesApi = useFetchAllCharteredCompaniesApi();
  const vehiclesApi = useFetchAllVehiclesApi();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const { token } = theme.useToken();

  const editApi = useEditDeliveryApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      setDateRange();
    }
  }, [editApi.loading]);

  const PageTypes = ["table", "kanban", "map"] as const;
  type PageType = (typeof PageTypes)[number];
  const query = useQuery<{ date: string; page: PageType }>();

  const [page, setPage] = useState<PageType>("table");
  useEffect(() => {
    setPage(query.page || "table");
    setSelectedDate(query.date ? new Date(query.date) : new Date());
  }, [query.date, query.page]);

  const deliveriesForm = useForm<Delivery[]>([]);

  const setDateRange = () => {
    const selectedDateOrDefault =
      selectedDate || (query.date ? new Date(query.date) : new Date());
    const date1 = new Date(),
      date2 = new Date();
    date1?.setFullYear(selectedDateOrDefault.getFullYear());
    date1?.setMonth(selectedDateOrDefault.getMonth());
    date1?.setDate(
      selectedDateOrDefault?.getDate() - selectedDateOrDefault?.getDay()
    );
    date2?.setFullYear(selectedDateOrDefault.getFullYear());
    date2?.setMonth(selectedDateOrDefault.getMonth());
    date2?.setDate(
      selectedDateOrDefault?.getDate() - selectedDateOrDefault?.getDay() + 7
    );
    setStartDate(date1);
    setEndDate(date2);
  };

  useEffect(() => {
    // Ensure startDate, endDate, and selectedDate are not undefined before comparison
    if (startDate && endDate && selectedDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const selected = dayjs(selectedDate);

      // If selected date is out of range
      if (selected.isBefore(start) || selected.isAfter(end)) {
        // Perform your actions here when the selectedDate is out of the range.
        setDateRange();
      }
    }
  }, [selectedDate]);

  useEffectSkipFirst(() => {
    const filters = `deliveryDetails.deliveryDate:${dayjs(startDate).format(
      "YYYY-MM-DD"
    )},${dayjs(endDate).format("YYYY-MM-DD")}`;
    const filterQuery = `?filter=${encodeURIComponent(filters)}`;
    listApi.execute(filterQuery);
  }, [startDate]);

  useEffect(() => {
    setDateRange();
    vehiclesApi.execute();
    charteredCompaniesApi.execute();
  }, []);
  const deliveries = (): Delivery[] => {
    return listApi.response.data || [];
  };

  const getDateRepresentingDelivery = (delivery: Delivery) => {
    let minLoadDate = "9999/12/31";
    delivery.deliveryDetails
      ?.filter((detail) => detail.pointType === 2)
      .forEach((detail) => {
        if (detail.deliveryDate && minLoadDate > detail.deliveryDate) {
          minLoadDate = detail.deliveryDate;
        }
      });
    return dayjs(minLoadDate).format("YYYY/MM/DD");
  };

  const deliveriesToday = (): Delivery[] => {
    return deliveries().filter((delivery) => {
      const minLoadDate = getDateRepresentingDelivery(delivery);
      return minLoadDate === dayjs(selectedDate).format("YYYY/MM/DD");
    });
  };

  useEffectSkipFirst(() => {
    globalState.setLoading(
      listApi.loading || vehiclesApi.loading || charteredCompaniesApi.loading
    );
    if (
      listApi.isSuccess() &&
      vehiclesApi.isSuccess() &&
      charteredCompaniesApi.isSuccess()
    ) {
      deliveriesForm.set(deliveriesToday());
    }
  }, [
    listApi.loading || vehiclesApi.loading || charteredCompaniesApi.loading,
    selectedDate,
  ]);

  return (
    <CustomPageHeader
      style={{
        borderBottom: `0px`,
      }}
      childrenStyle={{
        backgroundColor: token.colorWhite,
        padding: 0,
      }}
      title={"配車"}
      subTitle={
        <Skeleton loading={!selectedDate}>
          <Space size={10}>
            <div
              style={{
                fontWeight: 799,
                fontSize: 18,
              }}
            >
              積み日:
              {!selectedDate
                ? "--"
                : `${selectedDate!.getFullYear()}年${
                    selectedDate!.getMonth() + 1
                  }月`}
            </div>
            <Space size={0}>
              <CustomButton
                type="text"
                style={{ padding: 0, width: 24 }}
                icon={<LeftOutlined />}
                onClick={() => {
                  selectedDate!.setDate(selectedDate!.getDate() - 7);
                  setSelectedDate(new Date(selectedDate!.getTime()));
                  setDateRange();
                  props.history.push(
                    `/operations/deliveries?${QueryString.stringify({
                      page,
                      date: dayjs(selectedDate).format("YYYY-MM-DD"),
                    })}`
                  );
                }}
              />
              <CustomButton
                type="text"
                style={{ padding: 0, width: 24 }}
                icon={<RightOutlined />}
                onClick={() => {
                  selectedDate!.setDate(selectedDate!.getDate() + 7);
                  setSelectedDate(new Date(selectedDate!.getTime()));
                  setDateRange();
                  props.history.push(
                    `/operations/deliveries?${QueryString.stringify({
                      page,
                      date: dayjs(selectedDate).format("YYYY-MM-DD"),
                    })}`
                  );
                }}
              />
            </Space>
          </Space>
        </Skeleton>
      }
      extra={[
        <DeliveriesCalendar
          onOk={(e) => {
            props.history.push(
              `/operations/deliveries?${QueryString.stringify({
                page,
                date: dayjs(selectedDate).format("YYYY-MM-DD"),
              })}`
            );
          }}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          key={"calendarModal"}
          deliveries={listApi.response.data || []}
          getDateRepresentingDelivery={getDateRepresentingDelivery}
        />,
      ]}
      headerBottom={
        <WeekHorizontalView
          style={{ borderBottom: `1px solid ${token.colorBorder}` }}
          canHover
          startDate={startDate ?? new Date()}
          selectedDate={selectedDate ?? new Date()}
          setSelectedDate={(date) => {
            props.history.push(
              `/operations/deliveries?${QueryString.stringify({
                page,
                date: dayjs(date).format("YYYY-MM-DD"),
              })}`
            );
          }}
          dateShowDot={(date) =>
            deliveries()
              .map((delivery) => getDateRepresentingDelivery(delivery))
              .includes(dayjs(date).format("YYYY/MM/DD"))
          }
          width={"calc(100vw - 264px)"}
        />
      }
    >
      <Space
        style={{
          backgroundColor: token.colorBgBase,
          padding: "20px 32px 32px 32px",
          width: "100%",
        }}
        direction="vertical"
        size={16}
      >
        {deliveriesToday().length > 0 ? (
          <>
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <CustomSelectBlockField
                selectItems={[
                  {
                    label: "割当",
                    value: "table",
                  },
                  {
                    label: "配車",
                    value: "kanban",
                  },
                  {
                    label: "マップ",
                    value: "map",
                  },
                ]}
                value={page}
                onChange={(page) =>
                  props.history.push(
                    `/operations/deliveries?${QueryString.stringify({
                      page,
                      date: dayjs(selectedDate).format("YYYY-MM-DD"),
                    })}`
                  )
                }
              />
            </Space>
            {page === "table" && (
              <DeliveriesTable
                history={props.history}
                vehicles={vehiclesApi.response.data || []}
                charteredCompanies={charteredCompaniesApi.response.data || []}
                form={deliveriesForm}
                onEdit={(form: Delivery) => editApi.execute(form)}
              />
            )}
            {page === "kanban" && (
              <DeliveriesKanban
                history={props.history}
                form={deliveriesForm}
                vehicles={vehiclesApi.response.data || []}
                onEdit={(form: Delivery) => editApi.execute(form)}
              />
            )}
            {page === "map" && (
              <DeliveriesMaps
                history={props.history}
                vehicles={vehiclesApi.response.data || []}
                form={deliveriesForm}
                selectedDate={selectedDate!}
              />
            )}
          </>
        ) : (
          <Alert message="この日の配送データはありません。" />
        )}
      </Space>
    </CustomPageHeader>
  );
};
